<template>
<!--  <Map/>-->
  <MapFlanders/>
</template>

<script>
import Map from './components/Map.vue'
import MapFlanders from './components/MapFlanders.vue'
import bootstrap from 'bootstrap/scss/bootstrap.scss'

export default {
  name: 'App',
  components: {
    Map,
    MapFlanders
  },
  data () {
    return {
      mapData2000: [
        {
          "Woonplaats": "Aalst (Aalst)",
          "NIS code": 41002,
          "BE BE": "72,604",
          "BE BE %": "95%",
          "BE BU": "2,114",
          "BE BU %": "3%",
          "N BE": "1,595",
          "N BE %": "2%",
          "TOTAAL": "76,313"
        },
        {
          "Woonplaats": "Aalter",
          "NIS code": 44001,
          "BE BE": "18,121",
          "BE BE %": "98%",
          "BE BU": 241,
          "BE BU %": "1%",
          "N BE": 147,
          "N BE %": "1%",
          "TOTAAL": "18,509"
        },
        {
          "Woonplaats": "Aarlen",
          "NIS code": 81001,
          "BE BE": "20,732",
          "BE BE %": "84%",
          "BE BU": "2,273",
          "BE BU %": "9%",
          "N BE": "1,786",
          "N BE %": "7%",
          "TOTAAL": "24,791"
        },
        {
          "Woonplaats": "Aarschot",
          "NIS code": 24001,
          "BE BE": "26,616",
          "BE BE %": "97%",
          "BE BU": 554,
          "BE BU %": "2%",
          "N BE": 325,
          "N BE %": "1%",
          "TOTAAL": "27,495"
        },
        {
          "Woonplaats": "Aartselaar",
          "NIS code": 11001,
          "BE BE": "13,608",
          "BE BE %": "94%",
          "BE BU": 528,
          "BE BU %": "4%",
          "N BE": 302,
          "N BE %": "2%",
          "TOTAAL": "14,438"
        },
        {
          "Woonplaats": "Aat",
          "NIS code": 51004,
          "BE BE": "23,203",
          "BE BE %": "91%",
          "BE BU": "1,601",
          "BE BU %": "6%",
          "N BE": 626,
          "N BE %": "2%",
          "TOTAAL": "25,430"
        },
        {
          "Woonplaats": "Affligem",
          "NIS code": 23105,
          "BE BE": "11,226",
          "BE BE %": "96%",
          "BE BU": 271,
          "BE BU %": "2%",
          "N BE": 154,
          "N BE %": "1%",
          "TOTAAL": "11,651"
        },
        {
          "Woonplaats": "Aiseau-Presles",
          "NIS code": 52074,
          "BE BE": "6,429",
          "BE BE %": "59%",
          "BE BU": "2,400",
          "BE BU %": "22%",
          "N BE": "2,102",
          "N BE %": "19%",
          "TOTAAL": "10,931"
        },
        {
          "Woonplaats": "Alken",
          "NIS code": 73001,
          "BE BE": "10,394",
          "BE BE %": "95%",
          "BE BU": 317,
          "BE BU %": "3%",
          "N BE": 189,
          "N BE %": "2%",
          "TOTAAL": "10,900"
        },
        {
          "Woonplaats": "Alveringem",
          "NIS code": 38002,
          "BE BE": "4,617",
          "BE BE %": "96%",
          "BE BU": 115,
          "BE BU %": "2%",
          "N BE": 73,
          "N BE %": "2%",
          "TOTAAL": "4,805"
        },
        {
          "Woonplaats": "Amay",
          "NIS code": 61003,
          "BE BE": "10,470",
          "BE BE %": "81%",
          "BE BU": "1,956",
          "BE BU %": "15%",
          "N BE": 561,
          "N BE %": "4%",
          "TOTAAL": "12,987"
        },
        {
          "Woonplaats": "Amel",
          "NIS code": 63001,
          "BE BE": "4,752",
          "BE BE %": "94%",
          "BE BU": 139,
          "BE BU %": "3%",
          "N BE": 178,
          "N BE %": "4%",
          "TOTAAL": "5,069"
        },
        {
          "Woonplaats": "Andenne",
          "NIS code": 92003,
          "BE BE": "19,569",
          "BE BE %": "83%",
          "BE BU": "2,661",
          "BE BU %": "11%",
          "N BE": "1,299",
          "N BE %": "6%",
          "TOTAAL": "23,529"
        },
        {
          "Woonplaats": "Anderlecht",
          "NIS code": 21001,
          "BE BE": "47,814",
          "BE BE %": "54%",
          "BE BU": "17,143",
          "BE BU %": "20%",
          "N BE": "22,855",
          "N BE %": "26%",
          "TOTAAL": "87,812"
        },
        {
          "Woonplaats": "Anderlues",
          "NIS code": 56001,
          "BE BE": "8,041",
          "BE BE %": "70%",
          "BE BU": "2,047",
          "BE BU %": "18%",
          "N BE": "1,452",
          "N BE %": "13%",
          "TOTAAL": "11,540"
        },
        {
          "Woonplaats": "Anhée",
          "NIS code": 91005,
          "BE BE": "6,119",
          "BE BE %": "90%",
          "BE BU": 438,
          "BE BU %": "6%",
          "N BE": 214,
          "N BE %": "3%",
          "TOTAAL": "6,771"
        },
        {
          "Woonplaats": "Ans",
          "NIS code": 62003,
          "BE BE": "18,733",
          "BE BE %": "68%",
          "BE BU": "5,187",
          "BE BU %": "19%",
          "N BE": "3,608",
          "N BE %": "13%",
          "TOTAAL": "27,528"
        },
        {
          "Woonplaats": "Anthisnes",
          "NIS code": 61079,
          "BE BE": "3,401",
          "BE BE %": "88%",
          "BE BU": 360,
          "BE BU %": "9%",
          "N BE": 120,
          "N BE %": "3%",
          "TOTAAL": "3,881"
        },
        {
          "Woonplaats": "Antoing",
          "NIS code": 57003,
          "BE BE": "6,378",
          "BE BE %": "85%",
          "BE BU": 760,
          "BE BU %": "10%",
          "N BE": 343,
          "N BE %": "5%",
          "TOTAAL": "7,481"
        },
        {
          "Woonplaats": "Antwerpen",
          "NIS code": 11002,
          "BE BE": "344,737",
          "BE BE %": "77%",
          "BE BU": "42,268",
          "BE BU %": "9%",
          "N BE": "59,520",
          "N BE %": "13%",
          "TOTAAL": "446,525"
        },
        {
          "Woonplaats": "Anzegem",
          "NIS code": 34002,
          "BE BE": "13,477",
          "BE BE %": "98%",
          "BE BU": 168,
          "BE BU %": "1%",
          "N BE": 73,
          "N BE %": "1%",
          "TOTAAL": "13,718"
        },
        {
          "Woonplaats": "Ardooie",
          "NIS code": 37020,
          "BE BE": "9,321",
          "BE BE %": "98%",
          "BE BU": 117,
          "BE BU %": "1%",
          "N BE": 70,
          "N BE %": "1%",
          "TOTAAL": "9,508"
        },
        {
          "Woonplaats": "Arendonk",
          "NIS code": 13001,
          "BE BE": "10,178",
          "BE BE %": "86%",
          "BE BU": 853,
          "BE BU %": "7%",
          "N BE": 774,
          "N BE %": "7%",
          "TOTAAL": "11,805"
        },
        {
          "Woonplaats": "As",
          "NIS code": 71002,
          "BE BE": "6,038",
          "BE BE %": "84%",
          "BE BU": 806,
          "BE BU %": "11%",
          "N BE": 369,
          "N BE %": "5%",
          "TOTAAL": "7,213"
        },
        {
          "Woonplaats": "Asse",
          "NIS code": 23002,
          "BE BE": "25,210",
          "BE BE %": "90%",
          "BE BU": "1,611",
          "BE BU %": "6%",
          "N BE": "1,110",
          "N BE %": "4%",
          "TOTAAL": "27,931"
        },
        {
          "Woonplaats": "Assenede",
          "NIS code": 43002,
          "BE BE": "12,735",
          "BE BE %": "94%",
          "BE BU": 576,
          "BE BU %": "4%",
          "N BE": 286,
          "N BE %": "2%",
          "TOTAAL": "13,597"
        },
        {
          "Woonplaats": "Assesse",
          "NIS code": 92006,
          "BE BE": "5,568",
          "BE BE %": "93%",
          "BE BU": 333,
          "BE BU %": "6%",
          "N BE": 91,
          "N BE %": "2%",
          "TOTAAL": "5,992"
        },
        {
          "Woonplaats": "Attert",
          "NIS code": 81003,
          "BE BE": "3,619",
          "BE BE %": "88%",
          "BE BU": 331,
          "BE BU %": "8%",
          "N BE": 168,
          "N BE %": "4%",
          "TOTAAL": "4,118"
        },
        {
          "Woonplaats": "Aubange",
          "NIS code": 81004,
          "BE BE": "10,423",
          "BE BE %": "71%",
          "BE BU": "2,205",
          "BE BU %": "15%",
          "N BE": "2,035",
          "N BE %": "14%",
          "TOTAAL": "14,663"
        },
        {
          "Woonplaats": "Aubel",
          "NIS code": 63003,
          "BE BE": "3,455",
          "BE BE %": "90%",
          "BE BU": 256,
          "BE BU %": "7%",
          "N BE": 140,
          "N BE %": "4%",
          "TOTAAL": "3,851"
        },
        {
          "Woonplaats": "Avelgem",
          "NIS code": 34003,
          "BE BE": "8,796",
          "BE BE %": "96%",
          "BE BU": 240,
          "BE BU %": "3%",
          "N BE": 112,
          "N BE %": "1%",
          "TOTAAL": "9,148"
        },
        {
          "Woonplaats": "Awans",
          "NIS code": 62006,
          "BE BE": "6,899",
          "BE BE %": "83%",
          "BE BU": 982,
          "BE BU %": "12%",
          "N BE": 389,
          "N BE %": "5%",
          "TOTAAL": "8,270"
        },
        {
          "Woonplaats": "Aywaille",
          "NIS code": 62009,
          "BE BE": "8,880",
          "BE BE %": "88%",
          "BE BU": 932,
          "BE BU %": "9%",
          "N BE": 253,
          "N BE %": "3%",
          "TOTAAL": "10,065"
        },
        {
          "Woonplaats": "Baarle-Hertog",
          "NIS code": 13002,
          "BE BE": "1,002",
          "BE BE %": "47%",
          "BE BU": 354,
          "BE BU %": "17%",
          "N BE": 764,
          "N BE %": "36%",
          "TOTAAL": "2,120"
        },
        {
          "Woonplaats": "Baelen",
          "NIS code": 63004,
          "BE BE": "3,142",
          "BE BE %": "82%",
          "BE BU": 408,
          "BE BU %": "11%",
          "N BE": 302,
          "N BE %": "8%",
          "TOTAAL": "3,852"
        },
        {
          "Woonplaats": "Balen",
          "NIS code": 13003,
          "BE BE": "18,325",
          "BE BE %": "95%",
          "BE BU": 579,
          "BE BU %": "3%",
          "N BE": 386,
          "N BE %": "2%",
          "TOTAAL": "19,290"
        },
        {
          "Woonplaats": "Bastenaken",
          "NIS code": 82003,
          "BE BE": "11,980",
          "BE BE %": "89%",
          "BE BU": 848,
          "BE BU %": "6%",
          "N BE": 563,
          "N BE %": "4%",
          "TOTAAL": "13,391"
        },
        {
          "Woonplaats": "Beaumont",
          "NIS code": 56005,
          "BE BE": "5,686",
          "BE BE %": "86%",
          "BE BU": 717,
          "BE BU %": "11%",
          "N BE": 174,
          "N BE %": "3%",
          "TOTAAL": "6,577"
        },
        {
          "Woonplaats": "Beauraing",
          "NIS code": 91013,
          "BE BE": "7,217",
          "BE BE %": "89%",
          "BE BU": 617,
          "BE BU %": "8%",
          "N BE": 262,
          "N BE %": "3%",
          "TOTAAL": "8,096"
        },
        {
          "Woonplaats": "Beernem",
          "NIS code": 31003,
          "BE BE": "14,258",
          "BE BE %": "98%",
          "BE BU": 193,
          "BE BU %": "1%",
          "N BE": 74,
          "N BE %": "1%",
          "TOTAAL": "14,525"
        },
        {
          "Woonplaats": "Beerse",
          "NIS code": 13004,
          "BE BE": "14,697",
          "BE BE %": "94%",
          "BE BU": 519,
          "BE BU %": "3%",
          "N BE": 482,
          "N BE %": "3%",
          "TOTAAL": "15,698"
        },
        {
          "Woonplaats": "Beersel",
          "NIS code": 23003,
          "BE BE": "19,960",
          "BE BE %": "87%",
          "BE BU": "1,519",
          "BE BU %": "7%",
          "N BE": "1,401",
          "N BE %": "6%",
          "TOTAAL": "22,880"
        },
        {
          "Woonplaats": "Begijnendijk",
          "NIS code": 24007,
          "BE BE": "8,881",
          "BE BE %": "98%",
          "BE BU": 131,
          "BE BU %": "1%",
          "N BE": 76,
          "N BE %": "1%",
          "TOTAAL": "9,088"
        },
        {
          "Woonplaats": "Bekkevoort",
          "NIS code": 24008,
          "BE BE": "5,618",
          "BE BE %": "98%",
          "BE BU": 62,
          "BE BU %": "1%",
          "N BE": 46,
          "N BE %": "1%",
          "TOTAAL": "5,726"
        },
        {
          "Woonplaats": "Beloeil",
          "NIS code": 51008,
          "BE BE": "11,047",
          "BE BE %": "84%",
          "BE BU": "1,514",
          "BE BU %": "11%",
          "N BE": 665,
          "N BE %": "5%",
          "TOTAAL": "13,226"
        },
        {
          "Woonplaats": "Bergen",
          "NIS code": 53053,
          "BE BE": "63,585",
          "BE BE %": "70%",
          "BE BU": "14,018",
          "BE BU %": "15%",
          "N BE": "13,332",
          "N BE %": "15%",
          "TOTAAL": "90,935"
        },
        {
          "Woonplaats": "Beringen",
          "NIS code": 71004,
          "BE BE": "30,033",
          "BE BE %": "76%",
          "BE BU": "5,439",
          "BE BU %": "14%",
          "N BE": "3,789",
          "N BE %": "10%",
          "TOTAAL": "39,261"
        },
        {
          "Woonplaats": "Berlaar",
          "NIS code": 12002,
          "BE BE": "9,869",
          "BE BE %": "97%",
          "BE BU": 186,
          "BE BU %": "2%",
          "N BE": 95,
          "N BE %": "1%",
          "TOTAAL": "10,150"
        },
        {
          "Woonplaats": "Berlare",
          "NIS code": 42003,
          "BE BE": "13,302",
          "BE BE %": "97%",
          "BE BU": 269,
          "BE BU %": "2%",
          "N BE": 108,
          "N BE %": "1%",
          "TOTAAL": "13,679"
        },
        {
          "Woonplaats": "Berloz",
          "NIS code": 64008,
          "BE BE": "2,388",
          "BE BE %": "91%",
          "BE BU": 195,
          "BE BU %": "7%",
          "N BE": 37,
          "N BE %": "1%",
          "TOTAAL": "2,620"
        },
        {
          "Woonplaats": "Bernissart",
          "NIS code": 51009,
          "BE BE": "7,131",
          "BE BE %": "62%",
          "BE BU": "2,733",
          "BE BU %": "24%",
          "N BE": "1,569",
          "N BE %": "14%",
          "TOTAAL": "11,433"
        },
        {
          "Woonplaats": "Bertem",
          "NIS code": 24009,
          "BE BE": "8,041",
          "BE BE %": "91%",
          "BE BU": 253,
          "BE BU %": "3%",
          "N BE": 549,
          "N BE %": "6%",
          "TOTAAL": "8,843"
        },
        {
          "Woonplaats": "Bertogne",
          "NIS code": 82005,
          "BE BE": "2,610",
          "BE BE %": "96%",
          "BE BU": 93,
          "BE BU %": "3%",
          "N BE": 21,
          "N BE %": "1%",
          "TOTAAL": "2,724"
        },
        {
          "Woonplaats": "Bertrix",
          "NIS code": 84009,
          "BE BE": "7,266",
          "BE BE %": "90%",
          "BE BU": 535,
          "BE BU %": "7%",
          "N BE": 253,
          "N BE %": "3%",
          "TOTAAL": "8,054"
        },
        {
          "Woonplaats": "Bevekom",
          "NIS code": 25005,
          "BE BE": "5,532",
          "BE BE %": "89%",
          "BE BU": 443,
          "BE BU %": "7%",
          "N BE": 224,
          "N BE %": "4%",
          "TOTAAL": "6,199"
        },
        {
          "Woonplaats": "Bever",
          "NIS code": 23009,
          "BE BE": "1,866",
          "BE BE %": "94%",
          "BE BU": 70,
          "BE BU %": "4%",
          "N BE": 44,
          "N BE %": "2%",
          "TOTAAL": "1,980"
        },
        {
          "Woonplaats": "Beveren (Sint-Niklaas)",
          "NIS code": 46003,
          "BE BE": "42,741",
          "BE BE %": "95%",
          "BE BU": "1,525",
          "BE BU %": "3%",
          "N BE": 711,
          "N BE %": "2%",
          "TOTAAL": "44,977"
        },
        {
          "Woonplaats": "Beyne-Heusay",
          "NIS code": 62015,
          "BE BE": "7,882",
          "BE BE %": "68%",
          "BE BU": "2,233",
          "BE BU %": "19%",
          "N BE": "1,546",
          "N BE %": "13%",
          "TOTAAL": "11,661"
        },
        {
          "Woonplaats": "Bierbeek",
          "NIS code": 24011,
          "BE BE": "8,541",
          "BE BE %": "97%",
          "BE BU": 164,
          "BE BU %": "2%",
          "N BE": 117,
          "N BE %": "1%",
          "TOTAAL": "8,822"
        },
        {
          "Woonplaats": "Bièvre",
          "NIS code": 91015,
          "BE BE": "2,841",
          "BE BE %": "90%",
          "BE BU": 208,
          "BE BU %": "7%",
          "N BE": 91,
          "N BE %": "3%",
          "TOTAAL": "3,140"
        },
        {
          "Woonplaats": "Bilzen",
          "NIS code": 73006,
          "BE BE": "26,388",
          "BE BE %": "90%",
          "BE BU": "1,588",
          "BE BU %": "5%",
          "N BE": "1,359",
          "N BE %": "5%",
          "TOTAAL": "29,335"
        },
        {
          "Woonplaats": "Binche",
          "NIS code": 56011,
          "BE BE": "21,733",
          "BE BE %": "68%",
          "BE BU": "6,253",
          "BE BU %": "19%",
          "N BE": "4,204",
          "N BE %": "13%",
          "TOTAAL": "32,190"
        },
        {
          "Woonplaats": "Bitsingen",
          "NIS code": 62011,
          "BE BE": "6,981",
          "BE BE %": "85%",
          "BE BU": 759,
          "BE BU %": "9%",
          "N BE": 436,
          "N BE %": "5%",
          "TOTAAL": "8,176"
        },
        {
          "Woonplaats": "Blankenberge",
          "NIS code": 31004,
          "BE BE": "16,417",
          "BE BE %": "94%",
          "BE BU": 638,
          "BE BU %": "4%",
          "N BE": 359,
          "N BE %": "2%",
          "TOTAAL": "17,414"
        },
        {
          "Woonplaats": "Blegny",
          "NIS code": 62119,
          "BE BE": "10,238",
          "BE BE %": "83%",
          "BE BU": "1,504",
          "BE BU %": "12%",
          "N BE": 640,
          "N BE %": "5%",
          "TOTAAL": "12,382"
        },
        {
          "Woonplaats": "Plombieres",
          "NIS code": 63088,
          "BE BE": "5,960",
          "BE BE %": "64%",
          "BE BU": "1,402",
          "BE BU %": "15%",
          "N BE": "1,999",
          "N BE %": "21%",
          "TOTAAL": "9,361"
        },
        {
          "Woonplaats": "Bocholt",
          "NIS code": 72003,
          "BE BE": "10,523",
          "BE BE %": "89%",
          "BE BU": 536,
          "BE BU %": "5%",
          "N BE": 828,
          "N BE %": "7%",
          "TOTAAL": "11,887"
        },
        {
          "Woonplaats": "Boechout",
          "NIS code": 11004,
          "BE BE": "11,216",
          "BE BE %": "94%",
          "BE BU": 521,
          "BE BU %": "4%",
          "N BE": 182,
          "N BE %": "2%",
          "TOTAAL": "11,919"
        },
        {
          "Woonplaats": "Bonheiden",
          "NIS code": 12005,
          "BE BE": "13,532",
          "BE BE %": "96%",
          "BE BU": 298,
          "BE BU %": "2%",
          "N BE": 259,
          "N BE %": "2%",
          "TOTAAL": "14,089"
        },
        {
          "Woonplaats": "Boom",
          "NIS code": 11005,
          "BE BE": "13,174",
          "BE BE %": "87%",
          "BE BU": 878,
          "BE BU %": "6%",
          "N BE": "1,005",
          "N BE %": "7%",
          "TOTAAL": "15,057"
        },
        {
          "Woonplaats": "Boortmeerbeek",
          "NIS code": 24014,
          "BE BE": "10,907",
          "BE BE %": "96%",
          "BE BU": 283,
          "BE BU %": "2%",
          "N BE": 207,
          "N BE %": "2%",
          "TOTAAL": "11,397"
        },
        {
          "Woonplaats": "Borgloon",
          "NIS code": 73009,
          "BE BE": "9,742",
          "BE BE %": "96%",
          "BE BU": 172,
          "BE BU %": "2%",
          "N BE": 202,
          "N BE %": "2%",
          "TOTAAL": "10,116"
        },
        {
          "Woonplaats": "Borgworm",
          "NIS code": 64074,
          "BE BE": "12,045",
          "BE BE %": "91%",
          "BE BU": 849,
          "BE BU %": "6%",
          "N BE": 296,
          "N BE %": "2%",
          "TOTAAL": "13,190"
        },
        {
          "Woonplaats": "Bornem",
          "NIS code": 12007,
          "BE BE": "18,953",
          "BE BE %": "95%",
          "BE BU": 610,
          "BE BU %": "3%",
          "N BE": 376,
          "N BE %": "2%",
          "TOTAAL": "19,939"
        },
        {
          "Woonplaats": "Borsbeek",
          "NIS code": 11007,
          "BE BE": "9,437",
          "BE BE %": "91%",
          "BE BU": 549,
          "BE BU %": "5%",
          "N BE": 387,
          "N BE %": "4%",
          "TOTAAL": "10,373"
        },
        {
          "Woonplaats": "Bouillon",
          "NIS code": 84010,
          "BE BE": "4,587",
          "BE BE %": "84%",
          "BE BU": 577,
          "BE BU %": "11%",
          "N BE": 301,
          "N BE %": "6%",
          "TOTAAL": "5,465"
        },
        {
          "Woonplaats": "Boussu",
          "NIS code": 53014,
          "BE BE": "12,666",
          "BE BE %": "63%",
          "BE BU": "4,442",
          "BE BU %": "22%",
          "N BE": "2,951",
          "N BE %": "15%",
          "TOTAAL": "20,059"
        },
        {
          "Woonplaats": "Boutersem",
          "NIS code": 24016,
          "BE BE": "7,008",
          "BE BE %": "97%",
          "BE BU": 153,
          "BE BU %": "2%",
          "N BE": 73,
          "N BE %": "1%",
          "TOTAAL": "7,234"
        },
        {
          "Woonplaats": "Braives",
          "NIS code": 64015,
          "BE BE": "4,926",
          "BE BE %": "92%",
          "BE BU": 321,
          "BE BU %": "6%",
          "N BE": 105,
          "N BE %": "2%",
          "TOTAAL": "5,352"
        },
        {
          "Woonplaats": "Brakel",
          "NIS code": 45059,
          "BE BE": "13,455",
          "BE BE %": "98%",
          "BE BU": 184,
          "BE BU %": "1%",
          "N BE": 70,
          "N BE %": "1%",
          "TOTAAL": "13,709"
        },
        {
          "Woonplaats": "Brasschaat",
          "NIS code": 11008,
          "BE BE": "32,882",
          "BE BE %": "89%",
          "BE BU": "1,620",
          "BE BU %": "4%",
          "N BE": "2,636",
          "N BE %": "7%",
          "TOTAAL": "37,138"
        },
        {
          "Woonplaats": "Brecht",
          "NIS code": 11009,
          "BE BE": "23,309",
          "BE BE %": "93%",
          "BE BU": "1,016",
          "BE BU %": "4%",
          "N BE": 766,
          "N BE %": "3%",
          "TOTAAL": "25,091"
        },
        {
          "Woonplaats": "Bredene",
          "NIS code": 35002,
          "BE BE": "13,299",
          "BE BE %": "94%",
          "BE BU": 570,
          "BE BU %": "4%",
          "N BE": 207,
          "N BE %": "1%",
          "TOTAAL": "14,076"
        },
        {
          "Woonplaats": "Bree",
          "NIS code": 72004,
          "BE BE": "12,871",
          "BE BE %": "92%",
          "BE BU": 601,
          "BE BU %": "4%",
          "N BE": 529,
          "N BE %": "4%",
          "TOTAAL": "14,001"
        },
        {
          "Woonplaats": "Brugelette",
          "NIS code": 51012,
          "BE BE": "2,951",
          "BE BE %": "88%",
          "BE BU": 238,
          "BE BU %": "7%",
          "N BE": 155,
          "N BE %": "5%",
          "TOTAAL": "3,344"
        },
        {
          "Woonplaats": "Brugge",
          "NIS code": 31005,
          "BE BE": "110,264",
          "BE BE %": "95%",
          "BE BU": "3,382",
          "BE BU %": "3%",
          "N BE": "2,600",
          "N BE %": "2%",
          "TOTAAL": "116,246"
        },
        {
          "Woonplaats": "Brunehaut",
          "NIS code": 57093,
          "BE BE": "5,893",
          "BE BE %": "79%",
          "BE BU": 927,
          "BE BU %": "12%",
          "N BE": 626,
          "N BE %": "8%",
          "TOTAAL": "7,446"
        },
        {
          "Woonplaats": "Brussel",
          "NIS code": 21004,
          "BE BE": "59,516",
          "BE BE %": "44%",
          "BE BU": "30,609",
          "BE BU %": "23%",
          "N BE": "43,734",
          "N BE %": "33%",
          "TOTAAL": "133,859"
        },
        {
          "Woonplaats": "Buggenhout",
          "NIS code": 42004,
          "BE BE": "13,401",
          "BE BE %": "97%",
          "BE BU": 224,
          "BE BU %": "2%",
          "N BE": 127,
          "N BE %": "1%",
          "TOTAAL": "13,752"
        },
        {
          "Woonplaats": "Büllingen",
          "NIS code": 63012,
          "BE BE": "4,833",
          "BE BE %": "91%",
          "BE BU": 196,
          "BE BU %": "4%",
          "N BE": 258,
          "N BE %": "5%",
          "TOTAAL": "5,287"
        },
        {
          "Woonplaats": "Burdinne",
          "NIS code": 61010,
          "BE BE": "2,462",
          "BE BE %": "91%",
          "BE BU": 172,
          "BE BU %": "6%",
          "N BE": 75,
          "N BE %": "3%",
          "TOTAAL": "2,709"
        },
        {
          "Woonplaats": "Burg-Reuland",
          "NIS code": 63087,
          "BE BE": "3,302",
          "BE BE %": "87%",
          "BE BU": 237,
          "BE BU %": "6%",
          "N BE": 248,
          "N BE %": "7%",
          "TOTAAL": "3,787"
        },
        {
          "Woonplaats": "Bütgenbach",
          "NIS code": 63013,
          "BE BE": "5,010",
          "BE BE %": "90%",
          "BE BU": 312,
          "BE BU %": "6%",
          "N BE": 228,
          "N BE %": "4%",
          "TOTAAL": "5,550"
        },
        {
          "Woonplaats": "Celles (Doornik)",
          "NIS code": 57018,
          "BE BE": "4,951",
          "BE BE %": "91%",
          "BE BU": 279,
          "BE BU %": "5%",
          "N BE": 185,
          "N BE %": "3%",
          "TOTAAL": "5,415"
        },
        {
          "Woonplaats": "Cerfontaine",
          "NIS code": 93010,
          "BE BE": "3,956",
          "BE BE %": "91%",
          "BE BU": 288,
          "BE BU %": "7%",
          "N BE": 90,
          "N BE %": "2%",
          "TOTAAL": "4,334"
        },
        {
          "Woonplaats": "Chapelle-lez-Herlaimont",
          "NIS code": 52010,
          "BE BE": "7,785",
          "BE BE %": "56%",
          "BE BU": "3,100",
          "BE BU %": "22%",
          "N BE": "3,024",
          "N BE %": "22%",
          "TOTAAL": "13,909"
        },
        {
          "Woonplaats": "Charleroi",
          "NIS code": 52011,
          "BE BE": "134,060",
          "BE BE %": "67%",
          "BE BU": "34,893",
          "BE BU %": "17%",
          "N BE": "31,874",
          "N BE %": "16%",
          "TOTAAL": "200,827"
        },
        {
          "Woonplaats": "Chastre",
          "NIS code": 25117,
          "BE BE": "5,582",
          "BE BE %": "86%",
          "BE BU": 580,
          "BE BU %": "9%",
          "N BE": 325,
          "N BE %": "5%",
          "TOTAAL": "6,487"
        },
        {
          "Woonplaats": "Châtelet",
          "NIS code": 52012,
          "BE BE": "21,492",
          "BE BE %": "61%",
          "BE BU": "7,486",
          "BE BU %": "21%",
          "N BE": "6,474",
          "N BE %": "18%",
          "TOTAAL": "35,452"
        },
        {
          "Woonplaats": "Chaudfontaine",
          "NIS code": 62022,
          "BE BE": "17,766",
          "BE BE %": "86%",
          "BE BU": "1,958",
          "BE BU %": "10%",
          "N BE": 883,
          "N BE %": "4%",
          "TOTAAL": "20,607"
        },
        {
          "Woonplaats": "Chaumont-Gistoux",
          "NIS code": 25018,
          "BE BE": "8,775",
          "BE BE %": "87%",
          "BE BU": 868,
          "BE BU %": "9%",
          "N BE": 498,
          "N BE %": "5%",
          "TOTAAL": "10,141"
        },
        {
          "Woonplaats": "Chièvres",
          "NIS code": 51014,
          "BE BE": "5,459",
          "BE BE %": "91%",
          "BE BU": 360,
          "BE BU %": "6%",
          "N BE": 187,
          "N BE %": "3%",
          "TOTAAL": "6,006"
        },
        {
          "Woonplaats": "Chimay",
          "NIS code": 56016,
          "BE BE": "8,149",
          "BE BE %": "84%",
          "BE BU": "1,261",
          "BE BU %": "13%",
          "N BE": 349,
          "N BE %": "4%",
          "TOTAAL": "9,759"
        },
        {
          "Woonplaats": "Chiny",
          "NIS code": 85007,
          "BE BE": "4,359",
          "BE BE %": "89%",
          "BE BU": 368,
          "BE BU %": "8%",
          "N BE": 158,
          "N BE %": "3%",
          "TOTAAL": "4,885"
        },
        {
          "Woonplaats": "Ciney",
          "NIS code": 91030,
          "BE BE": "13,378",
          "BE BE %": "93%",
          "BE BU": 779,
          "BE BU %": "5%",
          "N BE": 295,
          "N BE %": "2%",
          "TOTAAL": "14,452"
        },
        {
          "Woonplaats": "Clavier",
          "NIS code": 61012,
          "BE BE": "3,732",
          "BE BE %": "91%",
          "BE BU": 248,
          "BE BU %": "6%",
          "N BE": 140,
          "N BE %": "3%",
          "TOTAAL": "4,120"
        },
        {
          "Woonplaats": "Colfontaine",
          "NIS code": 53082,
          "BE BE": "13,259",
          "BE BE %": "65%",
          "BE BU": "4,319",
          "BE BU %": "21%",
          "N BE": "2,902",
          "N BE %": "14%",
          "TOTAAL": "20,480"
        },
        {
          "Woonplaats": "Comblain-au-Pont",
          "NIS code": 62026,
          "BE BE": "4,446",
          "BE BE %": "86%",
          "BE BU": 460,
          "BE BU %": "9%",
          "N BE": 283,
          "N BE %": "5%",
          "TOTAAL": "5,189"
        },
        {
          "Woonplaats": "Courcelles",
          "NIS code": 52015,
          "BE BE": "20,157",
          "BE BE %": "68%",
          "BE BU": "5,578",
          "BE BU %": "19%",
          "N BE": "3,971",
          "N BE %": "13%",
          "TOTAAL": "29,706"
        },
        {
          "Woonplaats": "Court-Saint-Etienne",
          "NIS code": 25023,
          "BE BE": "7,157",
          "BE BE %": "82%",
          "BE BU": 983,
          "BE BU %": "11%",
          "N BE": 606,
          "N BE %": "7%",
          "TOTAAL": "8,746"
        },
        {
          "Woonplaats": "Couvin",
          "NIS code": 93014,
          "BE BE": "11,470",
          "BE BE %": "87%",
          "BE BU": "1,248",
          "BE BU %": "9%",
          "N BE": 531,
          "N BE %": "4%",
          "TOTAAL": "13,249"
        },
        {
          "Woonplaats": "Crisnée",
          "NIS code": 64021,
          "BE BE": "2,257",
          "BE BE %": "87%",
          "BE BU": 259,
          "BE BU %": "10%",
          "N BE": 68,
          "N BE %": "3%",
          "TOTAAL": "2,584"
        },
        {
          "Woonplaats": "Dalhem",
          "NIS code": 62027,
          "BE BE": "5,123",
          "BE BE %": "83%",
          "BE BU": 710,
          "BE BU %": "12%",
          "N BE": 312,
          "N BE %": "5%",
          "TOTAAL": "6,145"
        },
        {
          "Woonplaats": "Damme",
          "NIS code": 31006,
          "BE BE": "10,741",
          "BE BE %": "97%",
          "BE BU": 202,
          "BE BU %": "2%",
          "N BE": 115,
          "N BE %": "1%",
          "TOTAAL": "11,058"
        },
        {
          "Woonplaats": "Daverdisse",
          "NIS code": 84016,
          "BE BE": "1,269",
          "BE BE %": "93%",
          "BE BU": 70,
          "BE BU %": "5%",
          "N BE": 21,
          "N BE %": "2%",
          "TOTAAL": "1,360"
        },
        {
          "Woonplaats": "De Haan",
          "NIS code": 35029,
          "BE BE": "10,621",
          "BE BE %": "94%",
          "BE BU": 512,
          "BE BU %": "5%",
          "N BE": 222,
          "N BE %": "2%",
          "TOTAAL": "11,355"
        },
        {
          "Woonplaats": "De Panne",
          "NIS code": 38008,
          "BE BE": "8,420",
          "BE BE %": "87%",
          "BE BU": 686,
          "BE BU %": "7%",
          "N BE": 616,
          "N BE %": "6%",
          "TOTAAL": "9,722"
        },
        {
          "Woonplaats": "De Pinte",
          "NIS code": 44012,
          "BE BE": "9,710",
          "BE BE %": "96%",
          "BE BU": 265,
          "BE BU %": "3%",
          "N BE": 149,
          "N BE %": "1%",
          "TOTAAL": "10,124"
        },
        {
          "Woonplaats": "Deerlijk",
          "NIS code": 34009,
          "BE BE": "11,208",
          "BE BE %": "98%",
          "BE BU": 181,
          "BE BU %": "2%",
          "N BE": 71,
          "N BE %": "1%",
          "TOTAAL": "11,460"
        },
        {
          "Woonplaats": "Deinze",
          "NIS code": 44011,
          "BE BE": "26,578",
          "BE BE %": "96%",
          "BE BU": 586,
          "BE BU %": "2%",
          "N BE": 395,
          "N BE %": "1%",
          "TOTAAL": "27,559"
        },
        {
          "Woonplaats": "Denderleeuw",
          "NIS code": 41011,
          "BE BE": "16,275",
          "BE BE %": "96%",
          "BE BU": 485,
          "BE BU %": "3%",
          "N BE": 224,
          "N BE %": "1%",
          "TOTAAL": "16,984"
        },
        {
          "Woonplaats": "Dendermonde",
          "NIS code": 42006,
          "BE BE": "41,348",
          "BE BE %": "96%",
          "BE BU": "1,144",
          "BE BU %": "3%",
          "N BE": 645,
          "N BE %": "1%",
          "TOTAAL": "43,137"
        },
        {
          "Woonplaats": "Dentergem",
          "NIS code": 37002,
          "BE BE": "7,669",
          "BE BE %": "97%",
          "BE BU": 107,
          "BE BU %": "1%",
          "N BE": 92,
          "N BE %": "1%",
          "TOTAAL": "7,868"
        },
        {
          "Woonplaats": "Dessel",
          "NIS code": 13006,
          "BE BE": "8,046",
          "BE BE %": "94%",
          "BE BU": 277,
          "BE BU %": "3%",
          "N BE": 222,
          "N BE %": "3%",
          "TOTAAL": "8,545"
        },
        {
          "Woonplaats": "Destelbergen",
          "NIS code": 44013,
          "BE BE": "16,530",
          "BE BE %": "96%",
          "BE BU": 431,
          "BE BU %": "3%",
          "N BE": 211,
          "N BE %": "1%",
          "TOTAAL": "17,172"
        },
        {
          "Woonplaats": "Diepenbeek",
          "NIS code": 71011,
          "BE BE": "15,988",
          "BE BE %": "93%",
          "BE BU": 798,
          "BE BU %": "5%",
          "N BE": 387,
          "N BE %": "2%",
          "TOTAAL": "17,173"
        },
        {
          "Woonplaats": "Diest",
          "NIS code": 24020,
          "BE BE": "20,302",
          "BE BE %": "92%",
          "BE BU": 908,
          "BE BU %": "4%",
          "N BE": 922,
          "N BE %": "4%",
          "TOTAAL": "22,132"
        },
        {
          "Woonplaats": "Diksmuide",
          "NIS code": 32003,
          "BE BE": "15,009",
          "BE BE %": "97%",
          "BE BU": 314,
          "BE BU %": "2%",
          "N BE": 120,
          "N BE %": "1%",
          "TOTAAL": "15,443"
        },
        {
          "Woonplaats": "Dilbeek",
          "NIS code": 23016,
          "BE BE": "33,970",
          "BE BE %": "90%",
          "BE BU": "2,224",
          "BE BU %": "6%",
          "N BE": "1,528",
          "N BE %": "4%",
          "TOTAAL": "37,722"
        },
        {
          "Woonplaats": "Dilsen-Stokkem",
          "NIS code": 72041,
          "BE BE": "14,307",
          "BE BE %": "79%",
          "BE BU": "2,166",
          "BE BU %": "12%",
          "N BE": "1,714",
          "N BE %": "9%",
          "TOTAAL": "18,187"
        },
        {
          "Woonplaats": "Dinant",
          "NIS code": 91034,
          "BE BE": "11,230",
          "BE BE %": "88%",
          "BE BU": "1,091",
          "BE BU %": "9%",
          "N BE": 414,
          "N BE %": "3%",
          "TOTAAL": "12,735"
        },
        {
          "Woonplaats": "Dison",
          "NIS code": 63020,
          "BE BE": "10,633",
          "BE BE %": "76%",
          "BE BU": "1,903",
          "BE BU %": "14%",
          "N BE": "1,442",
          "N BE %": "10%",
          "TOTAAL": "13,978"
        },
        {
          "Woonplaats": "Doische",
          "NIS code": 93018,
          "BE BE": "2,550",
          "BE BE %": "90%",
          "BE BU": 206,
          "BE BU %": "7%",
          "N BE": 84,
          "N BE %": "3%",
          "TOTAAL": "2,840"
        },
        {
          "Woonplaats": "Donceel",
          "NIS code": 64023,
          "BE BE": "2,315",
          "BE BE %": "87%",
          "BE BU": 244,
          "BE BU %": "9%",
          "N BE": 94,
          "N BE %": "4%",
          "TOTAAL": "2,653"
        },
        {
          "Woonplaats": "Doornik",
          "NIS code": 57081,
          "BE BE": "56,057",
          "BE BE %": "83%",
          "BE BU": "7,331",
          "BE BU %": "11%",
          "N BE": "3,991",
          "N BE %": "6%",
          "TOTAAL": "67,379"
        },
        {
          "Woonplaats": "Dour",
          "NIS code": 53020,
          "BE BE": "12,322",
          "BE BE %": "73%",
          "BE BU": "3,189",
          "BE BU %": "19%",
          "N BE": "1,354",
          "N BE %": "8%",
          "TOTAAL": "16,865"
        },
        {
          "Woonplaats": "Drogenbos",
          "NIS code": 23098,
          "BE BE": "3,601",
          "BE BE %": "77%",
          "BE BU": 612,
          "BE BU %": "13%",
          "N BE": 480,
          "N BE %": "10%",
          "TOTAAL": "4,693"
        },
        {
          "Woonplaats": "Duffel",
          "NIS code": 12009,
          "BE BE": "15,302",
          "BE BE %": "96%",
          "BE BU": 406,
          "BE BU %": "3%",
          "N BE": 263,
          "N BE %": "2%",
          "TOTAAL": "15,971"
        },
        {
          "Woonplaats": "Durbuy",
          "NIS code": 83012,
          "BE BE": "9,083",
          "BE BE %": "92%",
          "BE BU": 488,
          "BE BU %": "5%",
          "N BE": 345,
          "N BE %": "3%",
          "TOTAAL": "9,916"
        },
        {
          "Woonplaats": "Ecaussinnes",
          "NIS code": 55050,
          "BE BE": "8,360",
          "BE BE %": "87%",
          "BE BU": 920,
          "BE BU %": "10%",
          "N BE": 340,
          "N BE %": "4%",
          "TOTAAL": "9,620"
        },
        {
          "Woonplaats": "Edegem",
          "NIS code": 11013,
          "BE BE": "20,356",
          "BE BE %": "91%",
          "BE BU": "1,289",
          "BE BU %": "6%",
          "N BE": 608,
          "N BE %": "3%",
          "TOTAAL": "22,253"
        },
        {
          "Woonplaats": "Edingen",
          "NIS code": 55010,
          "BE BE": "9,552",
          "BE BE %": "88%",
          "BE BU": 912,
          "BE BU %": "8%",
          "N BE": 399,
          "N BE %": "4%",
          "TOTAAL": "10,863"
        },
        {
          "Woonplaats": "Eeklo",
          "NIS code": 43005,
          "BE BE": "18,208",
          "BE BE %": "95%",
          "BE BU": 533,
          "BE BU %": "3%",
          "N BE": 328,
          "N BE %": "2%",
          "TOTAAL": "19,069"
        },
        {
          "Woonplaats": "Eghezée",
          "NIS code": 92035,
          "BE BE": "12,427",
          "BE BE %": "93%",
          "BE BU": 715,
          "BE BU %": "5%",
          "N BE": 272,
          "N BE %": "2%",
          "TOTAAL": "13,414"
        },
        {
          "Woonplaats": "Eigenbrakel",
          "NIS code": 25014,
          "BE BE": "29,395",
          "BE BE %": "83%",
          "BE BU": "3,383",
          "BE BU %": "10%",
          "N BE": "2,481",
          "N BE %": "7%",
          "TOTAAL": "35,259"
        },
        {
          "Woonplaats": "Elsene",
          "NIS code": 21009,
          "BE BE": "33,295",
          "BE BE %": "46%",
          "BE BU": "13,116",
          "BE BU %": "18%",
          "N BE": "26,763",
          "N BE %": "37%",
          "TOTAAL": "73,174"
        },
        {
          "Woonplaats": "Elzele",
          "NIS code": 51017,
          "BE BE": "5,190",
          "BE BE %": "93%",
          "BE BU": 269,
          "BE BU %": "5%",
          "N BE": 121,
          "N BE %": "2%",
          "TOTAAL": "5,580"
        },
        {
          "Woonplaats": "Engis",
          "NIS code": 61080,
          "BE BE": "4,411",
          "BE BE %": "78%",
          "BE BU": 806,
          "BE BU %": "14%",
          "N BE": 472,
          "N BE %": "8%",
          "TOTAAL": "5,689"
        },
        {
          "Woonplaats": "Erezée",
          "NIS code": 83013,
          "BE BE": "2,590",
          "BE BE %": "94%",
          "BE BU": 96,
          "BE BU %": "3%",
          "N BE": 59,
          "N BE %": "2%",
          "TOTAAL": "2,745"
        },
        {
          "Woonplaats": "Erpe-Mere",
          "NIS code": 41082,
          "BE BE": "18,831",
          "BE BE %": "98%",
          "BE BU": 289,
          "BE BU %": "2%",
          "N BE": 129,
          "N BE %": "1%",
          "TOTAAL": "19,249"
        },
        {
          "Woonplaats": "Erquelinnes",
          "NIS code": 56022,
          "BE BE": "6,894",
          "BE BE %": "71%",
          "BE BU": "1,908",
          "BE BU %": "20%",
          "N BE": 911,
          "N BE %": "9%",
          "TOTAAL": "9,713"
        },
        {
          "Woonplaats": "Esneux",
          "NIS code": 62032,
          "BE BE": "11,201",
          "BE BE %": "85%",
          "BE BU": "1,454",
          "BE BU %": "11%",
          "N BE": 523,
          "N BE %": "4%",
          "TOTAAL": "13,178"
        },
        {
          "Woonplaats": "Essen",
          "NIS code": 11016,
          "BE BE": "12,607",
          "BE BE %": "77%",
          "BE BU": "1,542",
          "BE BU %": "9%",
          "N BE": "2,258",
          "N BE %": "14%",
          "TOTAAL": "16,407"
        },
        {
          "Woonplaats": "Estinnes",
          "NIS code": 56085,
          "BE BE": "6,099",
          "BE BE %": "81%",
          "BE BU": "1,063",
          "BE BU %": "14%",
          "N BE": 359,
          "N BE %": "5%",
          "TOTAAL": "7,521"
        },
        {
          "Woonplaats": "Etalle",
          "NIS code": 85009,
          "BE BE": "4,446",
          "BE BE %": "91%",
          "BE BU": 302,
          "BE BU %": "6%",
          "N BE": 142,
          "N BE %": "3%",
          "TOTAAL": "4,890"
        },
        {
          "Woonplaats": "Etterbeek",
          "NIS code": 21005,
          "BE BE": "20,077",
          "BE BE %": "51%",
          "BE BU": "7,617",
          "BE BU %": "19%",
          "N BE": "11,710",
          "N BE %": "30%",
          "TOTAAL": "39,404"
        },
        {
          "Woonplaats": "Eupen",
          "NIS code": 63023,
          "BE BE": "13,838",
          "BE BE %": "79%",
          "BE BU": "1,667",
          "BE BU %": "10%",
          "N BE": "2,011",
          "N BE %": "11%",
          "TOTAAL": "17,516"
        },
        {
          "Woonplaats": "Evere",
          "NIS code": 21006,
          "BE BE": "20,731",
          "BE BE %": "66%",
          "BE BU": "5,577",
          "BE BU %": "18%",
          "N BE": "5,040",
          "N BE %": "16%",
          "TOTAAL": "31,348"
        },
        {
          "Woonplaats": "Evergem",
          "NIS code": 44019,
          "BE BE": "29,942",
          "BE BE %": "96%",
          "BE BU": 832,
          "BE BU %": "3%",
          "N BE": 381,
          "N BE %": "1%",
          "TOTAAL": "31,155"
        },
        {
          "Woonplaats": "Faimes",
          "NIS code": 64076,
          "BE BE": "2,842",
          "BE BE %": "90%",
          "BE BU": 244,
          "BE BU %": "8%",
          "N BE": 69,
          "N BE %": "2%",
          "TOTAAL": "3,155"
        },
        {
          "Woonplaats": "Farciennes",
          "NIS code": 52018,
          "BE BE": "5,998",
          "BE BE %": "53%",
          "BE BU": "2,525",
          "BE BU %": "22%",
          "N BE": "2,813",
          "N BE %": "25%",
          "TOTAAL": "11,336"
        },
        {
          "Woonplaats": "Fauvillers",
          "NIS code": 82009,
          "BE BE": "1,676",
          "BE BE %": "91%",
          "BE BU": 110,
          "BE BU %": "6%",
          "N BE": 52,
          "N BE %": "3%",
          "TOTAAL": "1,838"
        },
        {
          "Woonplaats": "Fernelmont",
          "NIS code": 92138,
          "BE BE": "5,837",
          "BE BE %": "93%",
          "BE BU": 342,
          "BE BU %": "5%",
          "N BE": 119,
          "N BE %": "2%",
          "TOTAAL": "6,298"
        },
        {
          "Woonplaats": "Ferrières",
          "NIS code": 61019,
          "BE BE": "3,884",
          "BE BE %": "92%",
          "BE BU": 238,
          "BE BU %": "6%",
          "N BE": 86,
          "N BE %": "2%",
          "TOTAAL": "4,208"
        },
        {
          "Woonplaats": "Fexhe-le-Haut-Clocher",
          "NIS code": 64025,
          "BE BE": "2,495",
          "BE BE %": "85%",
          "BE BU": 312,
          "BE BU %": "11%",
          "N BE": 123,
          "N BE %": "4%",
          "TOTAAL": "2,930"
        },
        {
          "Woonplaats": "Flémalle",
          "NIS code": 62120,
          "BE BE": "18,936",
          "BE BE %": "73%",
          "BE BU": "4,277",
          "BE BU %": "17%",
          "N BE": "2,564",
          "N BE %": "10%",
          "TOTAAL": "25,777"
        },
        {
          "Woonplaats": "Fléron",
          "NIS code": 62038,
          "BE BE": "11,050",
          "BE BE %": "70%",
          "BE BU": "2,920",
          "BE BU %": "18%",
          "N BE": "1,921",
          "N BE %": "12%",
          "TOTAAL": "15,891"
        },
        {
          "Woonplaats": "Fleurus",
          "NIS code": 52021,
          "BE BE": "15,062",
          "BE BE %": "67%",
          "BE BU": "3,996",
          "BE BU %": "18%",
          "N BE": "3,322",
          "N BE %": "15%",
          "TOTAAL": "22,380"
        },
        {
          "Woonplaats": "Floreffe",
          "NIS code": 92045,
          "BE BE": "6,347",
          "BE BE %": "89%",
          "BE BU": 558,
          "BE BU %": "8%",
          "N BE": 187,
          "N BE %": "3%",
          "TOTAAL": "7,092"
        },
        {
          "Woonplaats": "Florennes",
          "NIS code": 93022,
          "BE BE": "9,583",
          "BE BE %": "90%",
          "BE BU": 850,
          "BE BU %": "8%",
          "N BE": 235,
          "N BE %": "2%",
          "TOTAAL": "10,668"
        },
        {
          "Woonplaats": "Florenville",
          "NIS code": 85011,
          "BE BE": "4,749",
          "BE BE %": "86%",
          "BE BU": 533,
          "BE BU %": "10%",
          "N BE": 264,
          "N BE %": "5%",
          "TOTAAL": "5,546"
        },
        {
          "Woonplaats": "Fontaine-l’Evêque",
          "NIS code": 52022,
          "BE BE": "10,169",
          "BE BE %": "60%",
          "BE BU": "3,717",
          "BE BU %": "22%",
          "N BE": "3,082",
          "N BE %": "18%",
          "TOTAAL": "16,968"
        },
        {
          "Woonplaats": "Fosses-la-Ville",
          "NIS code": 92048,
          "BE BE": "7,757",
          "BE BE %": "89%",
          "BE BU": 755,
          "BE BU %": "9%",
          "N BE": 238,
          "N BE %": "3%",
          "TOTAAL": "8,750"
        },
        {
          "Woonplaats": "Frameries",
          "NIS code": 53028,
          "BE BE": "14,962",
          "BE BE %": "72%",
          "BE BU": "3,374",
          "BE BU %": "16%",
          "N BE": "2,343",
          "N BE %": "11%",
          "TOTAAL": "20,679"
        },
        {
          "Woonplaats": "Frasnes-lez-Anvaing",
          "NIS code": 51065,
          "BE BE": "9,927",
          "BE BE %": "91%",
          "BE BU": 675,
          "BE BU %": "6%",
          "N BE": 270,
          "N BE %": "2%",
          "TOTAAL": "10,872"
        },
        {
          "Woonplaats": "Froidchapelle",
          "NIS code": 56029,
          "BE BE": "2,976",
          "BE BE %": "90%",
          "BE BU": 251,
          "BE BU %": "8%",
          "N BE": 88,
          "N BE %": "3%",
          "TOTAAL": "3,315"
        },
        {
          "Woonplaats": "Galmaarden",
          "NIS code": 23023,
          "BE BE": "7,476",
          "BE BE %": "97%",
          "BE BU": 183,
          "BE BU %": "2%",
          "N BE": 82,
          "N BE %": "1%",
          "TOTAAL": "7,741"
        },
        {
          "Woonplaats": "Ganshoren",
          "NIS code": 21008,
          "BE BE": "14,362",
          "BE BE %": "73%",
          "BE BU": "3,188",
          "BE BU %": "16%",
          "N BE": "2,207",
          "N BE %": "11%",
          "TOTAAL": "19,757"
        },
        {
          "Woonplaats": "Gavere",
          "NIS code": 44020,
          "BE BE": "11,604",
          "BE BE %": "98%",
          "BE BU": 163,
          "BE BU %": "1%",
          "N BE": 80,
          "N BE %": "1%",
          "TOTAAL": "11,847"
        },
        {
          "Woonplaats": "Gedinne",
          "NIS code": 91054,
          "BE BE": "3,928",
          "BE BE %": "90%",
          "BE BU": 263,
          "BE BU %": "6%",
          "N BE": 153,
          "N BE %": "4%",
          "TOTAAL": "4,344"
        },
        {
          "Woonplaats": "Geel",
          "NIS code": 13008,
          "BE BE": "32,138",
          "BE BE %": "95%",
          "BE BU": 760,
          "BE BU %": "2%",
          "N BE": 779,
          "N BE %": "2%",
          "TOTAAL": "33,677"
        },
        {
          "Woonplaats": "Geer",
          "NIS code": 64029,
          "BE BE": "2,366",
          "BE BE %": "92%",
          "BE BU": 156,
          "BE BU %": "6%",
          "N BE": 48,
          "N BE %": "2%",
          "TOTAAL": "2,570"
        },
        {
          "Woonplaats": "Geetbets",
          "NIS code": 24028,
          "BE BE": "5,675",
          "BE BE %": "98%",
          "BE BU": 77,
          "BE BU %": "1%",
          "N BE": 41,
          "N BE %": "1%",
          "TOTAAL": "5,793"
        },
        {
          "Woonplaats": "Geldenaken",
          "NIS code": 25048,
          "BE BE": "10,331",
          "BE BE %": "90%",
          "BE BU": 779,
          "BE BU %": "7%",
          "N BE": 373,
          "N BE %": "3%",
          "TOTAAL": "11,483"
        },
        {
          "Woonplaats": "Gembloux",
          "NIS code": 92142,
          "BE BE": "17,990",
          "BE BE %": "88%",
          "BE BU": "1,469",
          "BE BU %": "7%",
          "N BE": "1,025",
          "N BE %": "5%",
          "TOTAAL": "20,484"
        },
        {
          "Woonplaats": "Genepiën",
          "NIS code": 25031,
          "BE BE": "11,672",
          "BE BE %": "85%",
          "BE BU": "1,333",
          "BE BU %": "10%",
          "N BE": 752,
          "N BE %": "5%",
          "TOTAAL": "13,757"
        },
        {
          "Woonplaats": "Genk",
          "NIS code": 71016,
          "BE BE": "31,936",
          "BE BE %": "51%",
          "BE BU": "18,439",
          "BE BU %": "29%",
          "N BE": "12,467",
          "N BE %": "20%",
          "TOTAAL": "62,842"
        },
        {
          "Woonplaats": "Gent",
          "NIS code": 44021,
          "BE BE": "191,403",
          "BE BE %": "85%",
          "BE BU": "15,545",
          "BE BU %": "7%",
          "N BE": "17,232",
          "N BE %": "8%",
          "TOTAAL": "224,180"
        },
        {
          "Woonplaats": "Geraardsbergen",
          "NIS code": 41018,
          "BE BE": "29,787",
          "BE BE %": "96%",
          "BE BU": 740,
          "BE BU %": "2%",
          "N BE": 418,
          "N BE %": "1%",
          "TOTAAL": "30,945"
        },
        {
          "Woonplaats": "Gerpinnes",
          "NIS code": 52025,
          "BE BE": "9,898",
          "BE BE %": "82%",
          "BE BU": "1,495",
          "BE BU %": "12%",
          "N BE": 657,
          "N BE %": "5%",
          "TOTAAL": "12,050"
        },
        {
          "Woonplaats": "Gesves",
          "NIS code": 92054,
          "BE BE": "5,321",
          "BE BE %": "92%",
          "BE BU": 361,
          "BE BU %": "6%",
          "N BE": 85,
          "N BE %": "1%",
          "TOTAAL": "5,767"
        },
        {
          "Woonplaats": "Gingelom",
          "NIS code": 71017,
          "BE BE": "7,320",
          "BE BE %": "96%",
          "BE BU": 186,
          "BE BU %": "2%",
          "N BE": 94,
          "N BE %": "1%",
          "TOTAAL": "7,600"
        },
        {
          "Woonplaats": "Gistel",
          "NIS code": 35005,
          "BE BE": "10,776",
          "BE BE %": "97%",
          "BE BU": 219,
          "BE BU %": "2%",
          "N BE": 90,
          "N BE %": "1%",
          "TOTAAL": "11,085"
        },
        {
          "Woonplaats": "Glabbeek",
          "NIS code": 24137,
          "BE BE": "5,004",
          "BE BE %": "99%",
          "BE BU": 52,
          "BE BU %": "1%",
          "N BE": 15,
          "N BE %": "0%",
          "TOTAAL": "5,071"
        },
        {
          "Woonplaats": "Gooik",
          "NIS code": 23024,
          "BE BE": "8,601",
          "BE BE %": "97%",
          "BE BU": 168,
          "BE BU %": "2%",
          "N BE": 65,
          "N BE %": "1%",
          "TOTAAL": "8,834"
        },
        {
          "Woonplaats": "Gouvy",
          "NIS code": 82037,
          "BE BE": "4,276",
          "BE BE %": "93%",
          "BE BU": 206,
          "BE BU %": "4%",
          "N BE": 99,
          "N BE %": "2%",
          "TOTAAL": "4,581"
        },
        {
          "Woonplaats": "Grâce-Hollogne",
          "NIS code": 62118,
          "BE BE": "13,398",
          "BE BE %": "60%",
          "BE BU": "4,821",
          "BE BU %": "21%",
          "N BE": "4,284",
          "N BE %": "19%",
          "TOTAAL": "22,503"
        },
        {
          "Woonplaats": "Graven",
          "NIS code": 25037,
          "BE BE": "10,045",
          "BE BE %": "85%",
          "BE BU": "1,152",
          "BE BU %": "10%",
          "N BE": 630,
          "N BE %": "5%",
          "TOTAAL": "11,827"
        },
        {
          "Woonplaats": "Grimbergen",
          "NIS code": 23025,
          "BE BE": "28,778",
          "BE BE %": "87%",
          "BE BU": "2,340",
          "BE BU %": "7%",
          "N BE": "1,812",
          "N BE %": "6%",
          "TOTAAL": "32,930"
        },
        {
          "Woonplaats": "Grobbendonk",
          "NIS code": 13010,
          "BE BE": "9,989",
          "BE BE %": "96%",
          "BE BU": 309,
          "BE BU %": "3%",
          "N BE": 151,
          "N BE %": "1%",
          "TOTAAL": "10,449"
        },
        {
          "Woonplaats": "Haacht",
          "NIS code": 24033,
          "BE BE": "12,667",
          "BE BE %": "96%",
          "BE BU": 335,
          "BE BU %": "3%",
          "N BE": 156,
          "N BE %": "1%",
          "TOTAAL": "13,158"
        },
        {
          "Woonplaats": "Haaltert",
          "NIS code": 41024,
          "BE BE": "16,892",
          "BE BE %": "98%",
          "BE BU": 265,
          "BE BU %": "2%",
          "N BE": 117,
          "N BE %": "1%",
          "TOTAAL": "17,274"
        },
        {
          "Woonplaats": "Habay",
          "NIS code": 85046,
          "BE BE": "6,338",
          "BE BE %": "91%",
          "BE BU": 418,
          "BE BU %": "6%",
          "N BE": 212,
          "N BE %": "3%",
          "TOTAAL": "6,968"
        },
        {
          "Woonplaats": "Halen",
          "NIS code": 71020,
          "BE BE": "7,931",
          "BE BE %": "97%",
          "BE BU": 170,
          "BE BU %": "2%",
          "N BE": 115,
          "N BE %": "1%",
          "TOTAAL": "8,216"
        },
        {
          "Woonplaats": "Halle (Halle-Vilvoorde)",
          "NIS code": 23027,
          "BE BE": "30,640",
          "BE BE %": "91%",
          "BE BU": "1,768",
          "BE BU %": "5%",
          "N BE": "1,247",
          "N BE %": "4%",
          "TOTAAL": "33,655"
        },
        {
          "Woonplaats": "Ham",
          "NIS code": 71069,
          "BE BE": "8,438",
          "BE BE %": "90%",
          "BE BU": 549,
          "BE BU %": "6%",
          "N BE": 339,
          "N BE %": "4%",
          "TOTAAL": "9,326"
        },
        {
          "Woonplaats": "Ham-sur-Heure-Nalinnes",
          "NIS code": 56086,
          "BE BE": "11,397",
          "BE BE %": "86%",
          "BE BU": "1,421",
          "BE BU %": "11%",
          "N BE": 435,
          "N BE %": "3%",
          "TOTAAL": "13,253"
        },
        {
          "Woonplaats": "Hamme (Dendermonde)",
          "NIS code": 42008,
          "BE BE": "21,537",
          "BE BE %": "95%",
          "BE BU": 674,
          "BE BU %": "3%",
          "N BE": 474,
          "N BE %": "2%",
          "TOTAAL": "22,685"
        },
        {
          "Woonplaats": "Hamoir",
          "NIS code": 61024,
          "BE BE": "3,217",
          "BE BE %": "92%",
          "BE BU": 197,
          "BE BU %": "6%",
          "N BE": 96,
          "N BE %": "3%",
          "TOTAAL": "3,510"
        },
        {
          "Woonplaats": "Hamois",
          "NIS code": 91059,
          "BE BE": "6,084",
          "BE BE %": "94%",
          "BE BU": 281,
          "BE BU %": "4%",
          "N BE": 97,
          "N BE %": "2%",
          "TOTAAL": "6,462"
        },
        {
          "Woonplaats": "Hamont-Achel",
          "NIS code": 72037,
          "BE BE": "8,450",
          "BE BE %": "62%",
          "BE BU": "2,067",
          "BE BU %": "15%",
          "N BE": "3,068",
          "N BE %": "23%",
          "TOTAAL": "13,585"
        },
        {
          "Woonplaats": "Hannuit",
          "NIS code": 64034,
          "BE BE": "12,113",
          "BE BE %": "91%",
          "BE BU": 837,
          "BE BU %": "6%",
          "N BE": 292,
          "N BE %": "2%",
          "TOTAAL": "13,242"
        },
        {
          "Woonplaats": "Harelbeke",
          "NIS code": 34013,
          "BE BE": "25,366",
          "BE BE %": "96%",
          "BE BU": 539,
          "BE BU %": "2%",
          "N BE": 415,
          "N BE %": "2%",
          "TOTAAL": "26,320"
        },
        {
          "Woonplaats": "Hasselt",
          "NIS code": 71022,
          "BE BE": "62,057",
          "BE BE %": "91%",
          "BE BU": "3,507",
          "BE BU %": "5%",
          "N BE": "2,494",
          "N BE %": "4%",
          "TOTAAL": "68,058"
        },
        {
          "Woonplaats": "Hastière",
          "NIS code": 91142,
          "BE BE": "4,252",
          "BE BE %": "85%",
          "BE BU": 434,
          "BE BU %": "9%",
          "N BE": 290,
          "N BE %": "6%",
          "TOTAAL": "4,976"
        },
        {
          "Woonplaats": "Havelange",
          "NIS code": 91064,
          "BE BE": "4,291",
          "BE BE %": "93%",
          "BE BU": 259,
          "BE BU %": "6%",
          "N BE": 72,
          "N BE %": "2%",
          "TOTAAL": "4,622"
        },
        {
          "Woonplaats": "Hechtel-Eksel",
          "NIS code": 72038,
          "BE BE": "9,900",
          "BE BE %": "89%",
          "BE BU": 533,
          "BE BU %": "5%",
          "N BE": 716,
          "N BE %": "6%",
          "TOTAAL": "11,149"
        },
        {
          "Woonplaats": "Heers",
          "NIS code": 73022,
          "BE BE": "6,296",
          "BE BE %": "96%",
          "BE BU": 184,
          "BE BU %": "3%",
          "N BE": 95,
          "N BE %": "1%",
          "TOTAAL": "6,575"
        },
        {
          "Woonplaats": "Heist-op-den-Berg",
          "NIS code": 12014,
          "BE BE": "36,278",
          "BE BE %": "97%",
          "BE BU": 623,
          "BE BU %": "2%",
          "N BE": 332,
          "N BE %": "1%",
          "TOTAAL": "37,233"
        },
        {
          "Woonplaats": "Hemiksem",
          "NIS code": 11018,
          "BE BE": "8,643",
          "BE BE %": "94%",
          "BE BU": 308,
          "BE BU %": "3%",
          "N BE": 200,
          "N BE %": "2%",
          "TOTAAL": "9,151"
        },
        {
          "Woonplaats": "Hensies",
          "NIS code": 53039,
          "BE BE": "4,183",
          "BE BE %": "62%",
          "BE BU": "1,637",
          "BE BU %": "24%",
          "N BE": 962,
          "N BE %": "14%",
          "TOTAAL": "6,782"
        },
        {
          "Woonplaats": "Herbeumont",
          "NIS code": 84029,
          "BE BE": "1,340",
          "BE BE %": "93%",
          "BE BU": 60,
          "BE BU %": "4%",
          "N BE": 34,
          "N BE %": "2%",
          "TOTAAL": "1,434"
        },
        {
          "Woonplaats": "Herent",
          "NIS code": 24038,
          "BE BE": "17,873",
          "BE BE %": "95%",
          "BE BU": 613,
          "BE BU %": "3%",
          "N BE": 362,
          "N BE %": "2%",
          "TOTAAL": "18,848"
        },
        {
          "Woonplaats": "Herentals",
          "NIS code": 13011,
          "BE BE": "24,102",
          "BE BE %": "95%",
          "BE BU": 898,
          "BE BU %": "4%",
          "N BE": 474,
          "N BE %": "2%",
          "TOTAAL": "25,474"
        },
        {
          "Woonplaats": "Herenthout",
          "NIS code": 13012,
          "BE BE": "8,083",
          "BE BE %": "97%",
          "BE BU": 163,
          "BE BU %": "2%",
          "N BE": 56,
          "N BE %": "1%",
          "TOTAAL": "8,302"
        },
        {
          "Woonplaats": "Herk-de-Stad",
          "NIS code": 71024,
          "BE BE": "11,207",
          "BE BE %": "96%",
          "BE BU": 223,
          "BE BU %": "2%",
          "N BE": 185,
          "N BE %": "2%",
          "TOTAAL": "11,615"
        },
        {
          "Woonplaats": "Herne",
          "NIS code": 23032,
          "BE BE": "6,061",
          "BE BE %": "95%",
          "BE BU": 209,
          "BE BU %": "3%",
          "N BE": 102,
          "N BE %": "2%",
          "TOTAAL": "6,372"
        },
        {
          "Woonplaats": "Héron",
          "NIS code": 61028,
          "BE BE": "3,875",
          "BE BE %": "92%",
          "BE BU": 208,
          "BE BU %": "5%",
          "N BE": 112,
          "N BE %": "3%",
          "TOTAAL": "4,195"
        },
        {
          "Woonplaats": "Herselt",
          "NIS code": 13013,
          "BE BE": "13,229",
          "BE BE %": "97%",
          "BE BU": 252,
          "BE BU %": "2%",
          "N BE": 151,
          "N BE %": "1%",
          "TOTAAL": "13,632"
        },
        {
          "Woonplaats": "Herstal",
          "NIS code": 62051,
          "BE BE": "21,701",
          "BE BE %": "60%",
          "BE BU": "7,109",
          "BE BU %": "20%",
          "N BE": "7,482",
          "N BE %": "21%",
          "TOTAAL": "36,292"
        },
        {
          "Woonplaats": "Herstappe",
          "NIS code": 73028,
          "BE BE": 79,
          "BE BE %": "93%",
          "BE BU": 6,
          "BE BU %": "7%",
          "N BE": 0,
          "N BE %": "0%",
          "TOTAAL": 85
        },
        {
          "Woonplaats": "Herve",
          "NIS code": 63035,
          "BE BE": "13,934",
          "BE BE %": "85%",
          "BE BU": "1,716",
          "BE BU %": "10%",
          "N BE": 819,
          "N BE %": "5%",
          "TOTAAL": "16,469"
        },
        {
          "Woonplaats": "Herzele",
          "NIS code": 41027,
          "BE BE": "16,039",
          "BE BE %": "99%",
          "BE BU": 158,
          "BE BU %": "1%",
          "N BE": 79,
          "N BE %": "0%",
          "TOTAAL": "16,276"
        },
        {
          "Woonplaats": "Heusden-Zolder",
          "NIS code": 71070,
          "BE BE": "21,845",
          "BE BE %": "73%",
          "BE BU": "4,694",
          "BE BU %": "16%",
          "N BE": "3,566",
          "N BE %": "12%",
          "TOTAAL": "30,105"
        },
        {
          "Woonplaats": "Heuvelland",
          "NIS code": 33039,
          "BE BE": "7,477",
          "BE BE %": "89%",
          "BE BU": 520,
          "BE BU %": "6%",
          "N BE": 402,
          "N BE %": "5%",
          "TOTAAL": "8,399"
        },
        {
          "Woonplaats": "Hélécine",
          "NIS code": 25118,
          "BE BE": "2,657",
          "BE BE %": "93%",
          "BE BU": 133,
          "BE BU %": "5%",
          "N BE": 58,
          "N BE %": "2%",
          "TOTAAL": "2,848"
        },
        {
          "Woonplaats": "Hoegaarden",
          "NIS code": 24041,
          "BE BE": "5,778",
          "BE BE %": "96%",
          "BE BU": 160,
          "BE BU %": "3%",
          "N BE": 76,
          "N BE %": "1%",
          "TOTAAL": "6,014"
        },
        {
          "Woonplaats": "Hoei",
          "NIS code": 61031,
          "BE BE": "16,178",
          "BE BE %": "85%",
          "BE BU": "1,856",
          "BE BU %": "10%",
          "N BE": "1,022",
          "N BE %": "5%",
          "TOTAAL": "19,056"
        },
        {
          "Woonplaats": "Hoeilaart",
          "NIS code": 23033,
          "BE BE": "7,731",
          "BE BE %": "79%",
          "BE BU": 659,
          "BE BU %": "7%",
          "N BE": "1,393",
          "N BE %": "14%",
          "TOTAAL": "9,783"
        },
        {
          "Woonplaats": "Hoeselt",
          "NIS code": 73032,
          "BE BE": "8,659",
          "BE BE %": "95%",
          "BE BU": 263,
          "BE BU %": "3%",
          "N BE": 211,
          "N BE %": "2%",
          "TOTAAL": "9,133"
        },
        {
          "Woonplaats": "Holsbeek",
          "NIS code": 24043,
          "BE BE": "8,582",
          "BE BE %": "97%",
          "BE BU": 185,
          "BE BU %": "2%",
          "N BE": 90,
          "N BE %": "1%",
          "TOTAAL": "8,857"
        },
        {
          "Woonplaats": "Honnelles",
          "NIS code": 53083,
          "BE BE": "4,074",
          "BE BE %": "81%",
          "BE BU": 672,
          "BE BU %": "13%",
          "N BE": 306,
          "N BE %": "6%",
          "TOTAAL": "5,052"
        },
        {
          "Woonplaats": "Hooglede",
          "NIS code": 36006,
          "BE BE": "9,552",
          "BE BE %": "98%",
          "BE BU": 131,
          "BE BU %": "1%",
          "N BE": 67,
          "N BE %": "1%",
          "TOTAAL": "9,750"
        },
        {
          "Woonplaats": "Hoogstraten",
          "NIS code": 13014,
          "BE BE": "12,976",
          "BE BE %": "73%",
          "BE BU": "2,015",
          "BE BU %": "11%",
          "N BE": "2,760",
          "N BE %": "16%",
          "TOTAAL": "17,751"
        },
        {
          "Woonplaats": "Horebeke",
          "NIS code": 45062,
          "BE BE": "1,941",
          "BE BE %": "99%",
          "BE BU": 20,
          "BE BU %": "1%",
          "N BE": 7,
          "N BE %": "0%",
          "TOTAAL": "1,968"
        },
        {
          "Woonplaats": "Hotton",
          "NIS code": 83028,
          "BE BE": "4,561",
          "BE BE %": "93%",
          "BE BU": 255,
          "BE BU %": "5%",
          "N BE": 74,
          "N BE %": "2%",
          "TOTAAL": "4,890"
        },
        {
          "Woonplaats": "Houffalize",
          "NIS code": 82014,
          "BE BE": "4,164",
          "BE BE %": "93%",
          "BE BU": 196,
          "BE BU %": "4%",
          "N BE": 141,
          "N BE %": "3%",
          "TOTAAL": "4,501"
        },
        {
          "Woonplaats": "Houthalen-Helchteren",
          "NIS code": 72039,
          "BE BE": "19,113",
          "BE BE %": "65%",
          "BE BU": "5,681",
          "BE BU %": "19%",
          "N BE": "4,421",
          "N BE %": "15%",
          "TOTAAL": "29,215"
        },
        {
          "Woonplaats": "Houthulst",
          "NIS code": 32006,
          "BE BE": "8,768",
          "BE BE %": "98%",
          "BE BU": 128,
          "BE BU %": "1%",
          "N BE": 50,
          "N BE %": "1%",
          "TOTAAL": "8,946"
        },
        {
          "Woonplaats": "Houyet",
          "NIS code": 91072,
          "BE BE": "4,057",
          "BE BE %": "92%",
          "BE BU": 268,
          "BE BU %": "6%",
          "N BE": 108,
          "N BE %": "2%",
          "TOTAAL": "4,433"
        },
        {
          "Woonplaats": "Hove (Antwerpen)",
          "NIS code": 11021,
          "BE BE": "7,830",
          "BE BE %": "94%",
          "BE BU": 311,
          "BE BU %": "4%",
          "N BE": 165,
          "N BE %": "2%",
          "TOTAAL": "8,306"
        },
        {
          "Woonplaats": "Huldenberg",
          "NIS code": 24045,
          "BE BE": "8,076",
          "BE BE %": "90%",
          "BE BU": 350,
          "BE BU %": "4%",
          "N BE": 583,
          "N BE %": "6%",
          "TOTAAL": "9,009"
        },
        {
          "Woonplaats": "Hulshout",
          "NIS code": 13016,
          "BE BE": "8,431",
          "BE BE %": "98%",
          "BE BU": 107,
          "BE BU %": "1%",
          "N BE": 88,
          "N BE %": "1%",
          "TOTAAL": "8,626"
        },
        {
          "Woonplaats": "Ichtegem",
          "NIS code": 35006,
          "BE BE": "12,990",
          "BE BE %": "98%",
          "BE BU": 180,
          "BE BU %": "1%",
          "N BE": 72,
          "N BE %": "1%",
          "TOTAAL": "13,242"
        },
        {
          "Woonplaats": "Ieper",
          "NIS code": 33011,
          "BE BE": "33,320",
          "BE BE %": "95%",
          "BE BU": "1,142",
          "BE BU %": "3%",
          "N BE": 609,
          "N BE %": "2%",
          "TOTAAL": "35,071"
        },
        {
          "Woonplaats": "Incourt",
          "NIS code": 25043,
          "BE BE": "3,707",
          "BE BE %": "90%",
          "BE BU": 292,
          "BE BU %": "7%",
          "N BE": 115,
          "N BE %": "3%",
          "TOTAAL": "4,114"
        },
        {
          "Woonplaats": "Ingelmunster",
          "NIS code": 36007,
          "BE BE": "10,450",
          "BE BE %": "98%",
          "BE BU": 141,
          "BE BU %": "1%",
          "N BE": 60,
          "N BE %": "1%",
          "TOTAAL": "10,651"
        },
        {
          "Woonplaats": "Itter",
          "NIS code": 25044,
          "BE BE": "4,901",
          "BE BE %": "87%",
          "BE BU": 459,
          "BE BU %": "8%",
          "N BE": 291,
          "N BE %": "5%",
          "TOTAAL": "5,651"
        },
        {
          "Woonplaats": "Izegem",
          "NIS code": 36008,
          "BE BE": "25,884",
          "BE BE %": "98%",
          "BE BU": 431,
          "BE BU %": "2%",
          "N BE": 217,
          "N BE %": "1%",
          "TOTAAL": "26,532"
        },
        {
          "Woonplaats": "Jabbeke",
          "NIS code": 31012,
          "BE BE": "13,232",
          "BE BE %": "97%",
          "BE BU": 229,
          "BE BU %": "2%",
          "N BE": 148,
          "N BE %": "1%",
          "TOTAAL": "13,609"
        },
        {
          "Woonplaats": "Jalhay",
          "NIS code": 63038,
          "BE BE": "6,868",
          "BE BE %": "92%",
          "BE BU": 474,
          "BE BU %": "6%",
          "N BE": 155,
          "N BE %": "2%",
          "TOTAAL": "7,497"
        },
        {
          "Woonplaats": "Jemeppe-sur-Sambre",
          "NIS code": 92140,
          "BE BE": "15,308",
          "BE BE %": "87%",
          "BE BU": "1,536",
          "BE BU %": "9%",
          "N BE": 679,
          "N BE %": "4%",
          "TOTAAL": "17,523"
        },
        {
          "Woonplaats": "Jette",
          "NIS code": 21010,
          "BE BE": "26,060",
          "BE BE %": "66%",
          "BE BU": "7,685",
          "BE BU %": "19%",
          "N BE": "6,004",
          "N BE %": "15%",
          "TOTAAL": "39,749"
        },
        {
          "Woonplaats": "Juprelle",
          "NIS code": 62060,
          "BE BE": "6,672",
          "BE BE %": "82%",
          "BE BU": "1,011",
          "BE BU %": "12%",
          "N BE": 462,
          "N BE %": "6%",
          "TOTAAL": "8,145"
        },
        {
          "Woonplaats": "Jurbeke",
          "NIS code": 53044,
          "BE BE": "7,367",
          "BE BE %": "80%",
          "BE BU": 853,
          "BE BU %": "9%",
          "N BE": "1,009",
          "N BE %": "11%",
          "TOTAAL": "9,229"
        },
        {
          "Woonplaats": "Kalmthout",
          "NIS code": 11022,
          "BE BE": "14,605",
          "BE BE %": "85%",
          "BE BU": "1,071",
          "BE BU %": "6%",
          "N BE": "1,584",
          "N BE %": "9%",
          "TOTAAL": "17,260"
        },
        {
          "Woonplaats": "Kampenhout",
          "NIS code": 23038,
          "BE BE": "10,119",
          "BE BE %": "94%",
          "BE BU": 330,
          "BE BU %": "3%",
          "N BE": 283,
          "N BE %": "3%",
          "TOTAAL": "10,732"
        },
        {
          "Woonplaats": "Kapelle-op-den-Bos",
          "NIS code": 23039,
          "BE BE": "8,467",
          "BE BE %": "96%",
          "BE BU": 214,
          "BE BU %": "2%",
          "N BE": 102,
          "N BE %": "1%",
          "TOTAAL": "8,783"
        },
        {
          "Woonplaats": "Kapellen (Antwerpen)",
          "NIS code": 11023,
          "BE BE": "22,264",
          "BE BE %": "87%",
          "BE BU": "1,416",
          "BE BU %": "6%",
          "N BE": "1,991",
          "N BE %": "8%",
          "TOTAAL": "25,671"
        },
        {
          "Woonplaats": "Kaprijke",
          "NIS code": 43007,
          "BE BE": "5,987",
          "BE BE %": "97%",
          "BE BU": 114,
          "BE BU %": "2%",
          "N BE": 74,
          "N BE %": "1%",
          "TOTAAL": "6,175"
        },
        {
          "Woonplaats": "Kasteelbrakel",
          "NIS code": 25015,
          "BE BE": "7,615",
          "BE BE %": "85%",
          "BE BU": 853,
          "BE BU %": "9%",
          "N BE": 526,
          "N BE %": "6%",
          "TOTAAL": "8,994"
        },
        {
          "Woonplaats": "Kasterlee",
          "NIS code": 13017,
          "BE BE": "16,709",
          "BE BE %": "95%",
          "BE BU": 439,
          "BE BU %": "3%",
          "N BE": 406,
          "N BE %": "2%",
          "TOTAAL": "17,554"
        },
        {
          "Woonplaats": "Keerbergen",
          "NIS code": 24048,
          "BE BE": "10,847",
          "BE BE %": "90%",
          "BE BU": 392,
          "BE BU %": "3%",
          "N BE": 753,
          "N BE %": "6%",
          "TOTAAL": "11,992"
        },
        {
          "Woonplaats": "Kelmis",
          "NIS code": 63040,
          "BE BE": "5,839",
          "BE BE %": "58%",
          "BE BU": 985,
          "BE BU %": "10%",
          "N BE": "3,297",
          "N BE %": "33%",
          "TOTAAL": "10,121"
        },
        {
          "Woonplaats": "Kinrooi",
          "NIS code": 72018,
          "BE BE": "9,122",
          "BE BE %": "78%",
          "BE BU": "1,350",
          "BE BU %": "11%",
          "N BE": "1,277",
          "N BE %": "11%",
          "TOTAAL": "11,749"
        },
        {
          "Woonplaats": "Mont-de-l’Enclus",
          "NIS code": 57095,
          "BE BE": "2,910",
          "BE BE %": "92%",
          "BE BU": 178,
          "BE BU %": "6%",
          "N BE": 84,
          "N BE %": "3%",
          "TOTAAL": "3,172"
        },
        {
          "Woonplaats": "Kluisbergen",
          "NIS code": 45060,
          "BE BE": "5,911",
          "BE BE %": "97%",
          "BE BU": 123,
          "BE BU %": "2%",
          "N BE": 52,
          "N BE %": "1%",
          "TOTAAL": "6,086"
        },
        {
          "Woonplaats": "Knesselare",
          "NIS code": 44029,
          "BE BE": "7,611",
          "BE BE %": "98%",
          "BE BU": 124,
          "BE BU %": "2%",
          "N BE": 59,
          "N BE %": "1%",
          "TOTAAL": "7,794"
        },
        {
          "Woonplaats": "Knokke-Heist",
          "NIS code": 31043,
          "BE BE": "30,351",
          "BE BE %": "92%",
          "BE BU": "1,551",
          "BE BU %": "5%",
          "N BE": "1,246",
          "N BE %": "4%",
          "TOTAAL": "33,148"
        },
        {
          "Woonplaats": "Koekelare",
          "NIS code": 32010,
          "BE BE": "7,946",
          "BE BE %": "97%",
          "BE BU": 153,
          "BE BU %": "2%",
          "N BE": 55,
          "N BE %": "1%",
          "TOTAAL": "8,154"
        },
        {
          "Woonplaats": "Koekelberg",
          "NIS code": 21011,
          "BE BE": "8,266",
          "BE BE %": "51%",
          "BE BU": "3,935",
          "BE BU %": "24%",
          "N BE": "4,010",
          "N BE %": "25%",
          "TOTAAL": "16,211"
        },
        {
          "Woonplaats": "Koksijde",
          "NIS code": 38014,
          "BE BE": "18,445",
          "BE BE %": "93%",
          "BE BU": 745,
          "BE BU %": "4%",
          "N BE": 539,
          "N BE %": "3%",
          "TOTAAL": "19,729"
        },
        {
          "Woonplaats": "Komen-Waasten",
          "NIS code": 54010,
          "BE BE": "10,905",
          "BE BE %": "62%",
          "BE BU": "3,299",
          "BE BU %": "19%",
          "N BE": "3,360",
          "N BE %": "19%",
          "TOTAAL": "17,564"
        },
        {
          "Woonplaats": "Kontich",
          "NIS code": 11024,
          "BE BE": "18,608",
          "BE BE %": "94%",
          "BE BU": 738,
          "BE BU %": "4%",
          "N BE": 528,
          "N BE %": "3%",
          "TOTAAL": "19,874"
        },
        {
          "Woonplaats": "Kortemark",
          "NIS code": 32011,
          "BE BE": "12,059",
          "BE BE %": "99%",
          "BE BU": 136,
          "BE BU %": "1%",
          "N BE": 37,
          "N BE %": "0%",
          "TOTAAL": "12,232"
        },
        {
          "Woonplaats": "Kortenaken",
          "NIS code": 24054,
          "BE BE": "7,317",
          "BE BE %": "98%",
          "BE BU": 87,
          "BE BU %": "1%",
          "N BE": 39,
          "N BE %": "1%",
          "TOTAAL": "7,443"
        },
        {
          "Woonplaats": "Kortenberg",
          "NIS code": 24055,
          "BE BE": "15,468",
          "BE BE %": "89%",
          "BE BU": 718,
          "BE BU %": "4%",
          "N BE": "1,169",
          "N BE %": "7%",
          "TOTAAL": "17,355"
        },
        {
          "Woonplaats": "Kortessem",
          "NIS code": 73040,
          "BE BE": "7,553",
          "BE BE %": "94%",
          "BE BU": 300,
          "BE BU %": "4%",
          "N BE": 157,
          "N BE %": "2%",
          "TOTAAL": "8,010"
        },
        {
          "Woonplaats": "Kortrijk",
          "NIS code": 34022,
          "BE BE": "69,863",
          "BE BE %": "93%",
          "BE BU": "2,575",
          "BE BU %": "3%",
          "N BE": "2,352",
          "N BE %": "3%",
          "TOTAAL": "74,790"
        },
        {
          "Woonplaats": "Kraainem",
          "NIS code": 23099,
          "BE BE": "8,513",
          "BE BE %": "67%",
          "BE BU": "1,247",
          "BE BU %": "10%",
          "N BE": "2,932",
          "N BE %": "23%",
          "TOTAAL": "12,692"
        },
        {
          "Woonplaats": "Kruibeke",
          "NIS code": 46013,
          "BE BE": "13,873",
          "BE BE %": "95%",
          "BE BU": 431,
          "BE BU %": "3%",
          "N BE": 252,
          "N BE %": "2%",
          "TOTAAL": "14,556"
        },
        {
          "Woonplaats": "Kruishoutem",
          "NIS code": 45017,
          "BE BE": "7,682",
          "BE BE %": "98%",
          "BE BU": 102,
          "BE BU %": "1%",
          "N BE": 65,
          "N BE %": "1%",
          "TOTAAL": "7,849"
        },
        {
          "Woonplaats": "Kuurne",
          "NIS code": 34023,
          "BE BE": "12,301",
          "BE BE %": "96%",
          "BE BU": 294,
          "BE BU %": "2%",
          "N BE": 190,
          "N BE %": "1%",
          "TOTAAL": "12,785"
        },
        {
          "Woonplaats": "La Bruyère",
          "NIS code": 92141,
          "BE BE": "7,333",
          "BE BE %": "94%",
          "BE BU": 391,
          "BE BU %": "5%",
          "N BE": 112,
          "N BE %": "1%",
          "TOTAAL": "7,836"
        },
        {
          "Woonplaats": "La Louvière",
          "NIS code": 55022,
          "BE BE": "43,544",
          "BE BE %": "57%",
          "BE BU": "16,438",
          "BE BU %": "21%",
          "N BE": "16,586",
          "N BE %": "22%",
          "TOTAAL": "76,568"
        },
        {
          "Woonplaats": "La Roche-en-Ardenne",
          "NIS code": 83031,
          "BE BE": "3,769",
          "BE BE %": "92%",
          "BE BU": 175,
          "BE BU %": "4%",
          "N BE": 163,
          "N BE %": "4%",
          "TOTAAL": "4,107"
        },
        {
          "Woonplaats": "Laakdal",
          "NIS code": 13053,
          "BE BE": "14,308",
          "BE BE %": "97%",
          "BE BU": 320,
          "BE BU %": "2%",
          "N BE": 178,
          "N BE %": "1%",
          "TOTAAL": "14,806"
        },
        {
          "Woonplaats": "Laarne",
          "NIS code": 42010,
          "BE BE": "11,338",
          "BE BE %": "98%",
          "BE BU": 188,
          "BE BU %": "2%",
          "N BE": 84,
          "N BE %": "1%",
          "TOTAAL": "11,610"
        },
        {
          "Woonplaats": "Lanaken",
          "NIS code": 73042,
          "BE BE": "16,774",
          "BE BE %": "71%",
          "BE BU": "1,898",
          "BE BU %": "8%",
          "N BE": "4,828",
          "N BE %": "21%",
          "TOTAAL": "23,500"
        },
        {
          "Woonplaats": "Landen",
          "NIS code": 24059,
          "BE BE": "13,771",
          "BE BE %": "96%",
          "BE BU": 339,
          "BE BU %": "2%",
          "N BE": 178,
          "N BE %": "1%",
          "TOTAAL": "14,288"
        },
        {
          "Woonplaats": "Langemark-Poelkapelle",
          "NIS code": 33040,
          "BE BE": "7,479",
          "BE BE %": "98%",
          "BE BU": 124,
          "BE BU %": "2%",
          "N BE": 53,
          "N BE %": "1%",
          "TOTAAL": "7,656"
        },
        {
          "Woonplaats": "Lasne",
          "NIS code": 25119,
          "BE BE": "10,410",
          "BE BE %": "77%",
          "BE BU": "1,227",
          "BE BU %": "9%",
          "N BE": "1,875",
          "N BE %": "14%",
          "TOTAAL": "13,512"
        },
        {
          "Woonplaats": "Le Roeulx",
          "NIS code": 55035,
          "BE BE": "6,249",
          "BE BE %": "79%",
          "BE BU": "1,093",
          "BE BU %": "14%",
          "N BE": 589,
          "N BE %": "7%",
          "TOTAAL": "7,931"
        },
        {
          "Woonplaats": "Lebbeke",
          "NIS code": 42011,
          "BE BE": "16,720",
          "BE BE %": "97%",
          "BE BU": 314,
          "BE BU %": "2%",
          "N BE": 200,
          "N BE %": "1%",
          "TOTAAL": "17,234"
        },
        {
          "Woonplaats": "Lede",
          "NIS code": 41034,
          "BE BE": "16,677",
          "BE BE %": "98%",
          "BE BU": 173,
          "BE BU %": "1%",
          "N BE": 99,
          "N BE %": "1%",
          "TOTAAL": "16,949"
        },
        {
          "Woonplaats": "Ledegem",
          "NIS code": 36010,
          "BE BE": "9,187",
          "BE BE %": "98%",
          "BE BU": 108,
          "BE BU %": "1%",
          "N BE": 49,
          "N BE %": "1%",
          "TOTAAL": "9,344"
        },
        {
          "Woonplaats": "Léglise",
          "NIS code": 84033,
          "BE BE": "3,446",
          "BE BE %": "93%",
          "BE BU": 173,
          "BE BU %": "5%",
          "N BE": 82,
          "N BE %": "2%",
          "TOTAAL": "3,701"
        },
        {
          "Woonplaats": "Lendelede",
          "NIS code": 34025,
          "BE BE": "5,418",
          "BE BE %": "98%",
          "BE BU": 56,
          "BE BU %": "1%",
          "N BE": 28,
          "N BE %": "1%",
          "TOTAAL": "5,502"
        },
        {
          "Woonplaats": "Lennik",
          "NIS code": 23104,
          "BE BE": "8,297",
          "BE BE %": "97%",
          "BE BU": 185,
          "BE BU %": "2%",
          "N BE": 106,
          "N BE %": "1%",
          "TOTAAL": "8,588"
        },
        {
          "Woonplaats": "Lens",
          "NIS code": 53046,
          "BE BE": "3,308",
          "BE BE %": "87%",
          "BE BU": 227,
          "BE BU %": "6%",
          "N BE": 257,
          "N BE %": "7%",
          "TOTAAL": "3,792"
        },
        {
          "Woonplaats": "Leopoldsburg",
          "NIS code": 71034,
          "BE BE": "11,720",
          "BE BE %": "85%",
          "BE BU": "1,245",
          "BE BU %": "9%",
          "N BE": 831,
          "N BE %": "6%",
          "TOTAAL": "13,796"
        },
        {
          "Woonplaats": "Les Bons Villers",
          "NIS code": 52075,
          "BE BE": "7,424",
          "BE BE %": "85%",
          "BE BU": 938,
          "BE BU %": "11%",
          "N BE": 366,
          "N BE %": "4%",
          "TOTAAL": "8,728"
        },
        {
          "Woonplaats": "Lessen",
          "NIS code": 55023,
          "BE BE": "15,048",
          "BE BE %": "88%",
          "BE BU": "1,486",
          "BE BU %": "9%",
          "N BE": 644,
          "N BE %": "4%",
          "TOTAAL": "17,178"
        },
        {
          "Woonplaats": "Leuven",
          "NIS code": 24062,
          "BE BE": "75,827",
          "BE BE %": "86%",
          "BE BU": "4,537",
          "BE BU %": "5%",
          "N BE": "7,650",
          "N BE %": "9%",
          "TOTAAL": "88,014"
        },
        {
          "Woonplaats": "Leuze-en-Hainaut",
          "NIS code": 57094,
          "BE BE": "11,713",
          "BE BE %": "90%",
          "BE BU": 919,
          "BE BU %": "7%",
          "N BE": 399,
          "N BE %": "3%",
          "TOTAAL": "13,031"
        },
        {
          "Woonplaats": "Libin",
          "NIS code": 84035,
          "BE BE": "4,080",
          "BE BE %": "94%",
          "BE BU": 188,
          "BE BU %": "4%",
          "N BE": 73,
          "N BE %": "2%",
          "TOTAAL": "4,341"
        },
        {
          "Woonplaats": "Libramont-Chevigny",
          "NIS code": 84077,
          "BE BE": "8,681",
          "BE BE %": "94%",
          "BE BU": 385,
          "BE BU %": "4%",
          "N BE": 211,
          "N BE %": "2%",
          "TOTAAL": "9,277"
        },
        {
          "Woonplaats": "Lichtervelde",
          "NIS code": 36011,
          "BE BE": "8,161",
          "BE BE %": "99%",
          "BE BU": 68,
          "BE BU %": "1%",
          "N BE": 41,
          "N BE %": "0%",
          "TOTAAL": "8,270"
        },
        {
          "Woonplaats": "Liedekerke",
          "NIS code": 23044,
          "BE BE": "11,412",
          "BE BE %": "97%",
          "BE BU": 266,
          "BE BU %": "2%",
          "N BE": 122,
          "N BE %": "1%",
          "TOTAAL": "11,800"
        },
        {
          "Woonplaats": "Lier",
          "NIS code": 12021,
          "BE BE": "29,575",
          "BE BE %": "92%",
          "BE BU": "1,302",
          "BE BU %": "4%",
          "N BE": "1,314",
          "N BE %": "4%",
          "TOTAAL": "32,191"
        },
        {
          "Woonplaats": "Lierde",
          "NIS code": 45063,
          "BE BE": "6,210",
          "BE BE %": "99%",
          "BE BU": 54,
          "BE BU %": "1%",
          "N BE": 27,
          "N BE %": "0%",
          "TOTAAL": "6,291"
        },
        {
          "Woonplaats": "Lierneux",
          "NIS code": 63045,
          "BE BE": "3,066",
          "BE BE %": "92%",
          "BE BU": 164,
          "BE BU %": "5%",
          "N BE": 87,
          "N BE %": "3%",
          "TOTAAL": "3,317"
        },
        {
          "Woonplaats": "Lille",
          "NIS code": 13019,
          "BE BE": "14,394",
          "BE BE %": "96%",
          "BE BU": 377,
          "BE BU %": "3%",
          "N BE": 250,
          "N BE %": "2%",
          "TOTAAL": "15,021"
        },
        {
          "Woonplaats": "Limburg",
          "NIS code": 63046,
          "BE BE": "4,731",
          "BE BE %": "86%",
          "BE BU": 494,
          "BE BU %": "9%",
          "N BE": 270,
          "N BE %": "5%",
          "TOTAAL": "5,495"
        },
        {
          "Woonplaats": "Lijsem",
          "NIS code": 64047,
          "BE BE": "2,618",
          "BE BE %": "93%",
          "BE BU": 143,
          "BE BU %": "5%",
          "N BE": 45,
          "N BE %": "2%",
          "TOTAAL": "2,806"
        },
        {
          "Woonplaats": "Linkebeek",
          "NIS code": 23100,
          "BE BE": "3,815",
          "BE BE %": "80%",
          "BE BU": 494,
          "BE BU %": "10%",
          "N BE": 442,
          "N BE %": "9%",
          "TOTAAL": "4,751"
        },
        {
          "Woonplaats": "Lint",
          "NIS code": 11025,
          "BE BE": "7,496",
          "BE BE %": "95%",
          "BE BU": 251,
          "BE BU %": "3%",
          "N BE": 117,
          "N BE %": "1%",
          "TOTAAL": "7,864"
        },
        {
          "Woonplaats": "Linter",
          "NIS code": 24133,
          "BE BE": "6,727",
          "BE BE %": "98%",
          "BE BU": 82,
          "BE BU %": "1%",
          "N BE": 43,
          "N BE %": "1%",
          "TOTAAL": "6,852"
        },
        {
          "Woonplaats": "Lo-Reninge",
          "NIS code": 32030,
          "BE BE": "3,153",
          "BE BE %": "98%",
          "BE BU": 43,
          "BE BU %": "1%",
          "N BE": 21,
          "N BE %": "1%",
          "TOTAAL": "3,217"
        },
        {
          "Woonplaats": "Lobbes",
          "NIS code": 56044,
          "BE BE": "4,652",
          "BE BE %": "84%",
          "BE BU": 707,
          "BE BU %": "13%",
          "N BE": 161,
          "N BE %": "3%",
          "TOTAAL": "5,520"
        },
        {
          "Woonplaats": "Lochristi",
          "NIS code": 44034,
          "BE BE": "18,263",
          "BE BE %": "97%",
          "BE BU": 412,
          "BE BU %": "2%",
          "N BE": 232,
          "N BE %": "1%",
          "TOTAAL": "18,907"
        },
        {
          "Woonplaats": "Lokeren",
          "NIS code": 46014,
          "BE BE": "32,132",
          "BE BE %": "88%",
          "BE BU": "1,596",
          "BE BU %": "4%",
          "N BE": "2,730",
          "N BE %": "7%",
          "TOTAAL": "36,458"
        },
        {
          "Woonplaats": "Lommel",
          "NIS code": 72020,
          "BE BE": "25,793",
          "BE BE %": "85%",
          "BE BU": "1,926",
          "BE BU %": "6%",
          "N BE": "2,714",
          "N BE %": "9%",
          "TOTAAL": "30,433"
        },
        {
          "Woonplaats": "Londerzeel",
          "NIS code": 23045,
          "BE BE": "16,575",
          "BE BE %": "97%",
          "BE BU": 337,
          "BE BU %": "2%",
          "N BE": 241,
          "N BE %": "1%",
          "TOTAAL": "17,153"
        },
        {
          "Woonplaats": "Lontzen",
          "NIS code": 63048,
          "BE BE": "3,360",
          "BE BE %": "67%",
          "BE BU": 554,
          "BE BU %": "11%",
          "N BE": "1,096",
          "N BE %": "22%",
          "TOTAAL": "5,010"
        },
        {
          "Woonplaats": "Lovendegem",
          "NIS code": 44036,
          "BE BE": "8,956",
          "BE BE %": "97%",
          "BE BU": 213,
          "BE BU %": "2%",
          "N BE": 95,
          "N BE %": "1%",
          "TOTAAL": "9,264"
        },
        {
          "Woonplaats": "Lubbeek",
          "NIS code": 24066,
          "BE BE": "13,160",
          "BE BE %": "97%",
          "BE BU": 282,
          "BE BU %": "2%",
          "N BE": 178,
          "N BE %": "1%",
          "TOTAAL": "13,620"
        },
        {
          "Woonplaats": "Luik",
          "NIS code": 62063,
          "BE BE": "120,331",
          "BE BE %": "65%",
          "BE BU": "33,141",
          "BE BU %": "18%",
          "N BE": "32,167",
          "N BE %": "17%",
          "TOTAAL": "185,639"
        },
        {
          "Woonplaats": "Lummen",
          "NIS code": 71037,
          "BE BE": "12,845",
          "BE BE %": "95%",
          "BE BU": 401,
          "BE BU %": "3%",
          "N BE": 255,
          "N BE %": "2%",
          "TOTAAL": "13,501"
        },
        {
          "Woonplaats": "Maarkedal",
          "NIS code": 45064,
          "BE BE": "6,320",
          "BE BE %": "98%",
          "BE BU": 70,
          "BE BU %": "1%",
          "N BE": 58,
          "N BE %": "1%",
          "TOTAAL": "6,448"
        },
        {
          "Woonplaats": "Maaseik",
          "NIS code": 72021,
          "BE BE": "19,064",
          "BE BE %": "83%",
          "BE BU": "1,609",
          "BE BU %": "7%",
          "N BE": "2,179",
          "N BE %": "10%",
          "TOTAAL": "22,852"
        },
        {
          "Woonplaats": "Maasmechelen",
          "NIS code": 73107,
          "BE BE": "18,841",
          "BE BE %": "53%",
          "BE BU": "8,988",
          "BE BU %": "25%",
          "N BE": "7,601",
          "N BE %": "21%",
          "TOTAAL": "35,430"
        },
        {
          "Woonplaats": "Machelen (Halle-Vilvoorde)",
          "NIS code": 23047,
          "BE BE": "9,797",
          "BE BE %": "83%",
          "BE BU": "1,191",
          "BE BU %": "10%",
          "N BE": 850,
          "N BE %": "7%",
          "TOTAAL": "11,838"
        },
        {
          "Woonplaats": "Maldegem",
          "NIS code": 43010,
          "BE BE": "20,950",
          "BE BE %": "95%",
          "BE BU": 780,
          "BE BU %": "4%",
          "N BE": 306,
          "N BE %": "1%",
          "TOTAAL": "22,036"
        },
        {
          "Woonplaats": "Malle",
          "NIS code": 11057,
          "BE BE": "13,129",
          "BE BE %": "94%",
          "BE BU": 493,
          "BE BU %": "4%",
          "N BE": 300,
          "N BE %": "2%",
          "TOTAAL": "13,922"
        },
        {
          "Woonplaats": "Malmedy",
          "NIS code": 63049,
          "BE BE": "9,825",
          "BE BE %": "88%",
          "BE BU": 782,
          "BE BU %": "7%",
          "N BE": 537,
          "N BE %": "5%",
          "TOTAAL": "11,144"
        },
        {
          "Woonplaats": "Manage",
          "NIS code": 52043,
          "BE BE": "14,565",
          "BE BE %": "67%",
          "BE BU": "3,894",
          "BE BU %": "18%",
          "N BE": "3,353",
          "N BE %": "15%",
          "TOTAAL": "21,812"
        },
        {
          "Woonplaats": "Manhay",
          "NIS code": 83055,
          "BE BE": "2,693",
          "BE BE %": "93%",
          "BE BU": 127,
          "BE BU %": "4%",
          "N BE": 65,
          "N BE %": "2%",
          "TOTAAL": "2,885"
        },
        {
          "Woonplaats": "Marche-en-Famenne",
          "NIS code": 83034,
          "BE BE": "14,655",
          "BE BE %": "89%",
          "BE BU": "1,080",
          "BE BU %": "7%",
          "N BE": 655,
          "N BE %": "4%",
          "TOTAAL": "16,390"
        },
        {
          "Woonplaats": "Marchin",
          "NIS code": 61039,
          "BE BE": "4,257",
          "BE BE %": "86%",
          "BE BU": 389,
          "BE BU %": "8%",
          "N BE": 289,
          "N BE %": "6%",
          "TOTAAL": "4,935"
        },
        {
          "Woonplaats": "Martelange",
          "NIS code": 81013,
          "BE BE": "1,217",
          "BE BE %": "85%",
          "BE BU": 121,
          "BE BU %": "8%",
          "N BE": 90,
          "N BE %": "6%",
          "TOTAAL": "1,428"
        },
        {
          "Woonplaats": "Mechelen",
          "NIS code": 12025,
          "BE BE": "63,577",
          "BE BE %": "84%",
          "BE BU": "5,266",
          "BE BU %": "7%",
          "N BE": "6,595",
          "N BE %": "9%",
          "TOTAAL": "75,438"
        },
        {
          "Woonplaats": "Meerhout",
          "NIS code": 13021,
          "BE BE": "8,922",
          "BE BE %": "97%",
          "BE BU": 160,
          "BE BU %": "2%",
          "N BE": 110,
          "N BE %": "1%",
          "TOTAAL": "9,192"
        },
        {
          "Woonplaats": "Meeuwen-Gruitrode",
          "NIS code": 72040,
          "BE BE": "11,654",
          "BE BE %": "93%",
          "BE BU": 460,
          "BE BU %": "4%",
          "N BE": 399,
          "N BE %": "3%",
          "TOTAAL": "12,513"
        },
        {
          "Woonplaats": "Meise",
          "NIS code": 23050,
          "BE BE": "16,765",
          "BE BE %": "91%",
          "BE BU": 929,
          "BE BU %": "5%",
          "N BE": 653,
          "N BE %": "4%",
          "TOTAAL": "18,347"
        },
        {
          "Woonplaats": "Meix-devant-Virton",
          "NIS code": 85024,
          "BE BE": "2,360",
          "BE BE %": "88%",
          "BE BU": 257,
          "BE BU %": "10%",
          "N BE": 74,
          "N BE %": "3%",
          "TOTAAL": "2,691"
        },
        {
          "Woonplaats": "Melle",
          "NIS code": 44040,
          "BE BE": "9,983",
          "BE BE %": "96%",
          "BE BU": 239,
          "BE BU %": "2%",
          "N BE": 127,
          "N BE %": "1%",
          "TOTAAL": "10,349"
        },
        {
          "Woonplaats": "Menen",
          "NIS code": 34027,
          "BE BE": "28,317",
          "BE BE %": "88%",
          "BE BU": "2,208",
          "BE BU %": "7%",
          "N BE": "1,503",
          "N BE %": "5%",
          "TOTAAL": "32,028"
        },
        {
          "Woonplaats": "Merbes-le-Château",
          "NIS code": 56049,
          "BE BE": "3,314",
          "BE BE %": "82%",
          "BE BU": 539,
          "BE BU %": "13%",
          "N BE": 179,
          "N BE %": "4%",
          "TOTAAL": "4,032"
        },
        {
          "Woonplaats": "Merchtem",
          "NIS code": 23052,
          "BE BE": "13,634",
          "BE BE %": "96%",
          "BE BU": 382,
          "BE BU %": "3%",
          "N BE": 234,
          "N BE %": "2%",
          "TOTAAL": "14,250"
        },
        {
          "Woonplaats": "Merelbeke",
          "NIS code": 44043,
          "BE BE": "21,080",
          "BE BE %": "97%",
          "BE BU": 479,
          "BE BU %": "2%",
          "N BE": 227,
          "N BE %": "1%",
          "TOTAAL": "21,786"
        },
        {
          "Woonplaats": "Merksplas",
          "NIS code": 13023,
          "BE BE": "7,376",
          "BE BE %": "92%",
          "BE BU": 366,
          "BE BU %": "5%",
          "N BE": 313,
          "N BE %": "4%",
          "TOTAAL": "8,055"
        },
        {
          "Woonplaats": "Mesen",
          "NIS code": 33016,
          "BE BE": 825,
          "BE BE %": "86%",
          "BE BU": 70,
          "BE BU %": "7%",
          "N BE": 69,
          "N BE %": "7%",
          "TOTAAL": 964
        },
        {
          "Woonplaats": "Messancy",
          "NIS code": 81015,
          "BE BE": "5,310",
          "BE BE %": "77%",
          "BE BU": 970,
          "BE BU %": "14%",
          "N BE": 622,
          "N BE %": "9%",
          "TOTAAL": "6,902"
        },
        {
          "Woonplaats": "Mettet",
          "NIS code": 92087,
          "BE BE": "10,105",
          "BE BE %": "89%",
          "BE BU": 923,
          "BE BU %": "8%",
          "N BE": 289,
          "N BE %": "3%",
          "TOTAAL": "11,317"
        },
        {
          "Woonplaats": "Meulebeke",
          "NIS code": 37007,
          "BE BE": "10,787",
          "BE BE %": "98%",
          "BE BU": 140,
          "BE BU %": "1%",
          "N BE": 128,
          "N BE %": "1%",
          "TOTAAL": "11,055"
        },
        {
          "Woonplaats": "Middelkerke",
          "NIS code": 35011,
          "BE BE": "15,807",
          "BE BE %": "95%",
          "BE BU": 512,
          "BE BU %": "3%",
          "N BE": 264,
          "N BE %": "2%",
          "TOTAAL": "16,583"
        },
        {
          "Woonplaats": "Modave",
          "NIS code": 61041,
          "BE BE": "3,084",
          "BE BE %": "86%",
          "BE BU": 347,
          "BE BU %": "10%",
          "N BE": 157,
          "N BE %": "4%",
          "TOTAAL": "3,588"
        },
        {
          "Woonplaats": "Moerbeke (Gent)",
          "NIS code": 44045,
          "BE BE": "5,336",
          "BE BE %": "93%",
          "BE BU": 251,
          "BE BU %": "4%",
          "N BE": 166,
          "N BE %": "3%",
          "TOTAAL": "5,753"
        },
        {
          "Woonplaats": "Moeskroen",
          "NIS code": 54007,
          "BE BE": "36,459",
          "BE BE %": "69%",
          "BE BU": "8,781",
          "BE BU %": "17%",
          "N BE": "7,252",
          "N BE %": "14%",
          "TOTAAL": "52,492"
        },
        {
          "Woonplaats": "Mol",
          "NIS code": 13025,
          "BE BE": "28,721",
          "BE BE %": "91%",
          "BE BU": "1,498",
          "BE BU %": "5%",
          "N BE": "1,464",
          "N BE %": "5%",
          "TOTAAL": "31,683"
        },
        {
          "Woonplaats": "Momignies",
          "NIS code": 56051,
          "BE BE": "3,661",
          "BE BE %": "71%",
          "BE BU": "1,119",
          "BE BU %": "22%",
          "N BE": 348,
          "N BE %": "7%",
          "TOTAAL": "5,128"
        },
        {
          "Woonplaats": "Mont-Saint-Guibert",
          "NIS code": 25068,
          "BE BE": "5,142",
          "BE BE %": "88%",
          "BE BU": 538,
          "BE BU %": "9%",
          "N BE": 191,
          "N BE %": "3%",
          "TOTAAL": "5,871"
        },
        {
          "Woonplaats": "Montigny-le-Tilleul",
          "NIS code": 52048,
          "BE BE": "8,246",
          "BE BE %": "80%",
          "BE BU": "1,473",
          "BE BU %": "14%",
          "N BE": 632,
          "N BE %": "6%",
          "TOTAAL": "10,351"
        },
        {
          "Woonplaats": "Moorslede",
          "NIS code": 36012,
          "BE BE": "10,513",
          "BE BE %": "98%",
          "BE BU": 166,
          "BE BU %": "2%",
          "N BE": 54,
          "N BE %": "1%",
          "TOTAAL": "10,733"
        },
        {
          "Woonplaats": "Morlanwelz",
          "NIS code": 56087,
          "BE BE": "11,322",
          "BE BE %": "61%",
          "BE BU": "3,639",
          "BE BU %": "20%",
          "N BE": "3,468",
          "N BE %": "19%",
          "TOTAAL": "18,429"
        },
        {
          "Woonplaats": "Mortsel",
          "NIS code": 11029,
          "BE BE": "23,337",
          "BE BE %": "93%",
          "BE BU": "1,049",
          "BE BU %": "4%",
          "N BE": 700,
          "N BE %": "3%",
          "TOTAAL": "25,086"
        },
        {
          "Woonplaats": "Musson",
          "NIS code": 85026,
          "BE BE": "3,071",
          "BE BE %": "79%",
          "BE BU": 455,
          "BE BU %": "12%",
          "N BE": 367,
          "N BE %": "9%",
          "TOTAAL": "3,893"
        },
        {
          "Woonplaats": "Namen",
          "NIS code": 92094,
          "BE BE": "89,867",
          "BE BE %": "85%",
          "BE BU": "9,067",
          "BE BU %": "9%",
          "N BE": "6,485",
          "N BE %": "6%",
          "TOTAAL": "105,419"
        },
        {
          "Woonplaats": "Nandrin",
          "NIS code": 61043,
          "BE BE": "4,621",
          "BE BE %": "86%",
          "BE BU": 565,
          "BE BU %": "11%",
          "N BE": 190,
          "N BE %": "4%",
          "TOTAAL": "5,376"
        },
        {
          "Woonplaats": "Nassogne",
          "NIS code": 83040,
          "BE BE": "4,405",
          "BE BE %": "93%",
          "BE BU": 241,
          "BE BU %": "5%",
          "N BE": 114,
          "N BE %": "2%",
          "TOTAAL": "4,760"
        },
        {
          "Woonplaats": "Nazareth",
          "NIS code": 44048,
          "BE BE": "10,584",
          "BE BE %": "97%",
          "BE BU": 188,
          "BE BU %": "2%",
          "N BE": 104,
          "N BE %": "1%",
          "TOTAAL": "10,876"
        },
        {
          "Woonplaats": "Neerpelt",
          "NIS code": 72025,
          "BE BE": "12,626",
          "BE BE %": "82%",
          "BE BU": "1,080",
          "BE BU %": "7%",
          "N BE": "1,672",
          "N BE %": "11%",
          "TOTAAL": "15,378"
        },
        {
          "Woonplaats": "Neufchâteau (Neufchâteau)",
          "NIS code": 84043,
          "BE BE": "5,573",
          "BE BE %": "90%",
          "BE BU": 436,
          "BE BU %": "7%",
          "N BE": 158,
          "N BE %": "3%",
          "TOTAAL": "6,167"
        },
        {
          "Woonplaats": "Neupré",
          "NIS code": 62121,
          "BE BE": "7,794",
          "BE BE %": "82%",
          "BE BU": "1,205",
          "BE BU %": "13%",
          "N BE": 514,
          "N BE %": "5%",
          "TOTAAL": "9,513"
        },
        {
          "Woonplaats": "Nevele",
          "NIS code": 44049,
          "BE BE": "10,813",
          "BE BE %": "98%",
          "BE BU": 119,
          "BE BU %": "1%",
          "N BE": 61,
          "N BE %": "1%",
          "TOTAAL": "10,993"
        },
        {
          "Woonplaats": "Niel",
          "NIS code": 11030,
          "BE BE": "7,889",
          "BE BE %": "94%",
          "BE BU": 273,
          "BE BU %": "3%",
          "N BE": 243,
          "N BE %": "3%",
          "TOTAAL": "8,405"
        },
        {
          "Woonplaats": "Nieuwerkerken (Hasselt)",
          "NIS code": 71045,
          "BE BE": "6,287",
          "BE BE %": "97%",
          "BE BU": 136,
          "BE BU %": "2%",
          "N BE": 60,
          "N BE %": "1%",
          "TOTAAL": "6,483"
        },
        {
          "Woonplaats": "Nieuwpoort",
          "NIS code": 38016,
          "BE BE": "9,801",
          "BE BE %": "96%",
          "BE BU": 293,
          "BE BU %": "3%",
          "N BE": 167,
          "N BE %": "2%",
          "TOTAAL": "10,261"
        },
        {
          "Woonplaats": "Nijlen",
          "NIS code": 12026,
          "BE BE": "19,790",
          "BE BE %": "97%",
          "BE BU": 459,
          "BE BU %": "2%",
          "N BE": 174,
          "N BE %": "1%",
          "TOTAAL": "20,423"
        },
        {
          "Woonplaats": "Nijvel",
          "NIS code": 25072,
          "BE BE": "20,087",
          "BE BE %": "85%",
          "BE BU": "2,263",
          "BE BU %": "10%",
          "N BE": "1,401",
          "N BE %": "6%",
          "TOTAAL": "23,751"
        },
        {
          "Woonplaats": "Ninove",
          "NIS code": 41048,
          "BE BE": "33,079",
          "BE BE %": "96%",
          "BE BU": 944,
          "BE BU %": "3%",
          "N BE": 536,
          "N BE %": "2%",
          "TOTAAL": "34,559"
        },
        {
          "Woonplaats": "Ohey",
          "NIS code": 92097,
          "BE BE": "3,765",
          "BE BE %": "93%",
          "BE BU": 224,
          "BE BU %": "6%",
          "N BE": 74,
          "N BE %": "2%",
          "TOTAAL": "4,063"
        },
        {
          "Woonplaats": "Olen",
          "NIS code": 13029,
          "BE BE": "10,409",
          "BE BE %": "96%",
          "BE BU": 331,
          "BE BU %": "3%",
          "N BE": 155,
          "N BE %": "1%",
          "TOTAAL": "10,895"
        },
        {
          "Woonplaats": "Olne",
          "NIS code": 63057,
          "BE BE": "3,135",
          "BE BE %": "84%",
          "BE BU": 452,
          "BE BU %": "12%",
          "N BE": 144,
          "N BE %": "4%",
          "TOTAAL": "3,731"
        },
        {
          "Woonplaats": "Onhaye",
          "NIS code": 91103,
          "BE BE": "2,764",
          "BE BE %": "92%",
          "BE BU": 193,
          "BE BU %": "6%",
          "N BE": 54,
          "N BE %": "2%",
          "TOTAAL": "3,011"
        },
        {
          "Woonplaats": "Oostende",
          "NIS code": 35013,
          "BE BE": "61,500",
          "BE BE %": "91%",
          "BE BU": "3,815",
          "BE BU %": "6%",
          "N BE": "1,964",
          "N BE %": "3%",
          "TOTAAL": "67,279"
        },
        {
          "Woonplaats": "Oosterzele",
          "NIS code": 44052,
          "BE BE": "12,926",
          "BE BE %": "98%",
          "BE BU": 194,
          "BE BU %": "1%",
          "N BE": 84,
          "N BE %": "1%",
          "TOTAAL": "13,204"
        },
        {
          "Woonplaats": "Oostkamp",
          "NIS code": 31022,
          "BE BE": "20,713",
          "BE BE %": "98%",
          "BE BU": 372,
          "BE BU %": "2%",
          "N BE": 133,
          "N BE %": "1%",
          "TOTAAL": "21,218"
        },
        {
          "Woonplaats": "Oostrozebeke",
          "NIS code": 37010,
          "BE BE": "7,160",
          "BE BE %": "98%",
          "BE BU": 100,
          "BE BU %": "1%",
          "N BE": 57,
          "N BE %": "1%",
          "TOTAAL": "7,317"
        },
        {
          "Woonplaats": "Opglabbeek",
          "NIS code": 71047,
          "BE BE": "7,867",
          "BE BE %": "86%",
          "BE BU": 742,
          "BE BU %": "8%",
          "N BE": 522,
          "N BE %": "6%",
          "TOTAAL": "9,131"
        },
        {
          "Woonplaats": "Opwijk",
          "NIS code": 23060,
          "BE BE": "11,426",
          "BE BE %": "98%",
          "BE BU": 174,
          "BE BU %": "1%",
          "N BE": 118,
          "N BE %": "1%",
          "TOTAAL": "11,718"
        },
        {
          "Woonplaats": "Opzullik",
          "NIS code": 55039,
          "BE BE": "6,621",
          "BE BE %": "89%",
          "BE BU": 598,
          "BE BU %": "8%",
          "N BE": 252,
          "N BE %": "3%",
          "TOTAAL": "7,471"
        },
        {
          "Woonplaats": "Oerle",
          "NIS code": 64056,
          "BE BE": "3,011",
          "BE BE %": "91%",
          "BE BU": 224,
          "BE BU %": "7%",
          "N BE": 82,
          "N BE %": "2%",
          "TOTAAL": "3,317"
        },
        {
          "Woonplaats": "Orp-Jauche",
          "NIS code": 25120,
          "BE BE": "6,730",
          "BE BE %": "90%",
          "BE BU": 513,
          "BE BU %": "7%",
          "N BE": 194,
          "N BE %": "3%",
          "TOTAAL": "7,437"
        },
        {
          "Woonplaats": "Ottignies-Louvain-la-Neuve",
          "NIS code": 25121,
          "BE BE": "20,120",
          "BE BE %": "73%",
          "BE BU": "3,473",
          "BE BU %": "13%",
          "N BE": "3,787",
          "N BE %": "14%",
          "TOTAAL": "27,380"
        },
        {
          "Woonplaats": "Oud-Heverlee",
          "NIS code": 24086,
          "BE BE": "9,968",
          "BE BE %": "95%",
          "BE BU": 306,
          "BE BU %": "3%",
          "N BE": 248,
          "N BE %": "2%",
          "TOTAAL": "10,522"
        },
        {
          "Woonplaats": "Oud-Turnhout",
          "NIS code": 13031,
          "BE BE": "10,719",
          "BE BE %": "86%",
          "BE BU": 612,
          "BE BU %": "5%",
          "N BE": "1,082",
          "N BE %": "9%",
          "TOTAAL": "12,413"
        },
        {
          "Woonplaats": "Oudenaarde",
          "NIS code": 45035,
          "BE BE": "26,964",
          "BE BE %": "97%",
          "BE BU": 466,
          "BE BU %": "2%",
          "N BE": 358,
          "N BE %": "1%",
          "TOTAAL": "27,788"
        },
        {
          "Woonplaats": "Oudenburg",
          "NIS code": 35014,
          "BE BE": "8,567",
          "BE BE %": "97%",
          "BE BU": 189,
          "BE BU %": "2%",
          "N BE": 54,
          "N BE %": "1%",
          "TOTAAL": "8,810"
        },
        {
          "Woonplaats": "Oudergem",
          "NIS code": 21002,
          "BE BE": "20,539",
          "BE BE %": "71%",
          "BE BU": "3,152",
          "BE BU %": "11%",
          "N BE": "5,113",
          "N BE %": "18%",
          "TOTAAL": "28,804"
        },
        {
          "Woonplaats": "Ouffet",
          "NIS code": 61048,
          "BE BE": "2,223",
          "BE BE %": "90%",
          "BE BU": 189,
          "BE BU %": "8%",
          "N BE": 62,
          "N BE %": "3%",
          "TOTAAL": "2,474"
        },
        {
          "Woonplaats": "Oupeye",
          "NIS code": 62079,
          "BE BE": "18,422",
          "BE BE %": "78%",
          "BE BU": "3,487",
          "BE BU %": "15%",
          "N BE": "1,811",
          "N BE %": "8%",
          "TOTAAL": "23,720"
        },
        {
          "Woonplaats": "Overijse",
          "NIS code": 23062,
          "BE BE": "17,502",
          "BE BE %": "74%",
          "BE BU": "1,379",
          "BE BU %": "6%",
          "N BE": "4,857",
          "N BE %": "20%",
          "TOTAAL": "23,738"
        },
        {
          "Woonplaats": "Overpelt",
          "NIS code": 72029,
          "BE BE": "11,285",
          "BE BE %": "90%",
          "BE BU": 638,
          "BE BU %": "5%",
          "N BE": 610,
          "N BE %": "5%",
          "TOTAAL": "12,533"
        },
        {
          "Woonplaats": "Paliseul",
          "NIS code": 84050,
          "BE BE": "4,700",
          "BE BE %": "94%",
          "BE BU": 213,
          "BE BU %": "4%",
          "N BE": 93,
          "N BE %": "2%",
          "TOTAAL": "5,006"
        },
        {
          "Woonplaats": "Pecq",
          "NIS code": 57062,
          "BE BE": "4,423",
          "BE BE %": "86%",
          "BE BU": 459,
          "BE BU %": "9%",
          "N BE": 275,
          "N BE %": "5%",
          "TOTAAL": "5,157"
        },
        {
          "Woonplaats": "Peer",
          "NIS code": 72030,
          "BE BE": "14,108",
          "BE BE %": "93%",
          "BE BU": 643,
          "BE BU %": "4%",
          "N BE": 500,
          "N BE %": "3%",
          "TOTAAL": "15,251"
        },
        {
          "Woonplaats": "Pepingen",
          "NIS code": 23064,
          "BE BE": "4,085",
          "BE BE %": "96%",
          "BE BU": 116,
          "BE BU %": "3%",
          "N BE": 46,
          "N BE %": "1%",
          "TOTAAL": "4,247"
        },
        {
          "Woonplaats": "Pepinster",
          "NIS code": 63058,
          "BE BE": "8,280",
          "BE BE %": "89%",
          "BE BU": 740,
          "BE BU %": "8%",
          "N BE": 272,
          "N BE %": "3%",
          "TOTAAL": "9,292"
        },
        {
          "Woonplaats": "Péruwelz",
          "NIS code": 57064,
          "BE BE": "12,264",
          "BE BE %": "73%",
          "BE BU": "2,563",
          "BE BU %": "15%",
          "N BE": "2,040",
          "N BE %": "12%",
          "TOTAAL": "16,867"
        },
        {
          "Woonplaats": "Perwijs (Nijvel)",
          "NIS code": 25084,
          "BE BE": "6,424",
          "BE BE %": "91%",
          "BE BU": 463,
          "BE BU %": "7%",
          "N BE": 170,
          "N BE %": "2%",
          "TOTAAL": "7,057"
        },
        {
          "Woonplaats": "Philippeville",
          "NIS code": 93056,
          "BE BE": "6,993",
          "BE BE %": "88%",
          "BE BU": 677,
          "BE BU %": "9%",
          "N BE": 269,
          "N BE %": "3%",
          "TOTAAL": "7,939"
        },
        {
          "Woonplaats": "Pittem",
          "NIS code": 37011,
          "BE BE": "6,529",
          "BE BE %": "98%",
          "BE BU": 56,
          "BE BU %": "1%",
          "N BE": 44,
          "N BE %": "1%",
          "TOTAAL": "6,629"
        },
        {
          "Woonplaats": "Pont-à-Celles",
          "NIS code": 52055,
          "BE BE": "13,671",
          "BE BE %": "85%",
          "BE BU": "1,797",
          "BE BU %": "11%",
          "N BE": 613,
          "N BE %": "4%",
          "TOTAAL": "16,081"
        },
        {
          "Woonplaats": "Poperinge",
          "NIS code": 33021,
          "BE BE": "18,154",
          "BE BE %": "94%",
          "BE BU": 789,
          "BE BU %": "4%",
          "N BE": 311,
          "N BE %": "2%",
          "TOTAAL": "19,254"
        },
        {
          "Woonplaats": "Profondeville",
          "NIS code": 92101,
          "BE BE": "9,678",
          "BE BE %": "90%",
          "BE BU": 773,
          "BE BU %": "7%",
          "N BE": 290,
          "N BE %": "3%",
          "TOTAAL": "10,741"
        },
        {
          "Woonplaats": "Putte",
          "NIS code": 12029,
          "BE BE": "14,982",
          "BE BE %": "97%",
          "BE BU": 242,
          "BE BU %": "2%",
          "N BE": 170,
          "N BE %": "1%",
          "TOTAAL": "15,394"
        },
        {
          "Woonplaats": "Puurs",
          "NIS code": 12030,
          "BE BE": "15,247",
          "BE BE %": "97%",
          "BE BU": 331,
          "BE BU %": "2%",
          "N BE": 158,
          "N BE %": "1%",
          "TOTAAL": "15,736"
        },
        {
          "Woonplaats": "Quaregnon",
          "NIS code": 53065,
          "BE BE": "10,809",
          "BE BE %": "56%",
          "BE BU": "4,743",
          "BE BU %": "25%",
          "N BE": "3,626",
          "N BE %": "19%",
          "TOTAAL": "19,178"
        },
        {
          "Woonplaats": "Quévy",
          "NIS code": 53084,
          "BE BE": "6,068",
          "BE BE %": "81%",
          "BE BU": "1,021",
          "BE BU %": "14%",
          "N BE": 385,
          "N BE %": "5%",
          "TOTAAL": "7,474"
        },
        {
          "Woonplaats": "Quiévrain",
          "NIS code": 53068,
          "BE BE": "4,142",
          "BE BE %": "62%",
          "BE BU": "1,556",
          "BE BU %": "23%",
          "N BE": "1,015",
          "N BE %": "15%",
          "TOTAAL": "6,713"
        },
        {
          "Woonplaats": "Raeren",
          "NIS code": 63061,
          "BE BE": "4,126",
          "BE BE %": "43%",
          "BE BU": 916,
          "BE BU %": "10%",
          "N BE": "4,508",
          "N BE %": "47%",
          "TOTAAL": "9,550"
        },
        {
          "Woonplaats": "Ramillies",
          "NIS code": 25122,
          "BE BE": "4,841",
          "BE BE %": "91%",
          "BE BU": 333,
          "BE BU %": "6%",
          "N BE": 121,
          "N BE %": "2%",
          "TOTAAL": "5,295"
        },
        {
          "Woonplaats": "Ranst",
          "NIS code": 11035,
          "BE BE": "16,961",
          "BE BE %": "97%",
          "BE BU": 415,
          "BE BU %": "2%",
          "N BE": 199,
          "N BE %": "1%",
          "TOTAAL": "17,575"
        },
        {
          "Woonplaats": "Ravels",
          "NIS code": 13035,
          "BE BE": "9,097",
          "BE BE %": "69%",
          "BE BU": "1,393",
          "BE BU %": "11%",
          "N BE": "2,609",
          "N BE %": "20%",
          "TOTAAL": "13,099"
        },
        {
          "Woonplaats": "Rebecq",
          "NIS code": 25123,
          "BE BE": "7,811",
          "BE BE %": "79%",
          "BE BU": "1,193",
          "BE BU %": "12%",
          "N BE": 873,
          "N BE %": "9%",
          "TOTAAL": "9,877"
        },
        {
          "Woonplaats": "Remicourt",
          "NIS code": 64063,
          "BE BE": "4,357",
          "BE BE %": "92%",
          "BE BU": 302,
          "BE BU %": "6%",
          "N BE": 94,
          "N BE %": "2%",
          "TOTAAL": "4,753"
        },
        {
          "Woonplaats": "Rendeux",
          "NIS code": 83044,
          "BE BE": "2,069",
          "BE BE %": "93%",
          "BE BU": 88,
          "BE BU %": "4%",
          "N BE": 60,
          "N BE %": "3%",
          "TOTAAL": "2,217"
        },
        {
          "Woonplaats": "Retie",
          "NIS code": 13036,
          "BE BE": "9,048",
          "BE BE %": "93%",
          "BE BU": 300,
          "BE BU %": "3%",
          "N BE": 385,
          "N BE %": "4%",
          "TOTAAL": "9,733"
        },
        {
          "Woonplaats": "Riemst",
          "NIS code": 73066,
          "BE BE": "12,838",
          "BE BE %": "82%",
          "BE BU": 789,
          "BE BU %": "5%",
          "N BE": "1,990",
          "N BE %": "13%",
          "TOTAAL": "15,617"
        },
        {
          "Woonplaats": "Rijkevorsel",
          "NIS code": 13037,
          "BE BE": "9,741",
          "BE BE %": "93%",
          "BE BU": 475,
          "BE BU %": "5%",
          "N BE": 239,
          "N BE %": "2%",
          "TOTAAL": "10,455"
        },
        {
          "Woonplaats": "Rixensart",
          "NIS code": 25091,
          "BE BE": "17,317",
          "BE BE %": "81%",
          "BE BU": "2,064",
          "BE BU %": "10%",
          "N BE": "1,890",
          "N BE %": "9%",
          "TOTAAL": "21,271"
        },
        {
          "Woonplaats": "Rochefort",
          "NIS code": 91114,
          "BE BE": "10,853",
          "BE BE %": "92%",
          "BE BU": 637,
          "BE BU %": "5%",
          "N BE": 257,
          "N BE %": "2%",
          "TOTAAL": "11,747"
        },
        {
          "Woonplaats": "Roeselare",
          "NIS code": 36015,
          "BE BE": "52,466",
          "BE BE %": "97%",
          "BE BU": "1,139",
          "BE BU %": "2%",
          "N BE": 594,
          "N BE %": "1%",
          "TOTAAL": "54,199"
        },
        {
          "Woonplaats": "Ronse",
          "NIS code": 45041,
          "BE BE": "20,456",
          "BE BE %": "86%",
          "BE BU": "1,860",
          "BE BU %": "8%",
          "N BE": "1,533",
          "N BE %": "6%",
          "TOTAAL": "23,849"
        },
        {
          "Woonplaats": "Roosdaal",
          "NIS code": 23097,
          "BE BE": "10,258",
          "BE BE %": "97%",
          "BE BU": 218,
          "BE BU %": "2%",
          "N BE": 97,
          "N BE %": "1%",
          "TOTAAL": "10,573"
        },
        {
          "Woonplaats": "Rotselaar",
          "NIS code": 24094,
          "BE BE": "14,030",
          "BE BE %": "96%",
          "BE BU": 356,
          "BE BU %": "2%",
          "N BE": 201,
          "N BE %": "1%",
          "TOTAAL": "14,587"
        },
        {
          "Woonplaats": "Rouvroy",
          "NIS code": 85047,
          "BE BE": "1,489",
          "BE BE %": "79%",
          "BE BU": 253,
          "BE BU %": "13%",
          "N BE": 135,
          "N BE %": "7%",
          "TOTAAL": "1,877"
        },
        {
          "Woonplaats": "Ruiselede",
          "NIS code": 37012,
          "BE BE": "4,966",
          "BE BE %": "98%",
          "BE BU": 33,
          "BE BU %": "1%",
          "N BE": 49,
          "N BE %": "1%",
          "TOTAAL": "5,048"
        },
        {
          "Woonplaats": "Rumes",
          "NIS code": 57072,
          "BE BE": "4,248",
          "BE BE %": "84%",
          "BE BU": 549,
          "BE BU %": "11%",
          "N BE": 267,
          "N BE %": "5%",
          "TOTAAL": "5,064"
        },
        {
          "Woonplaats": "Rumst",
          "NIS code": 11037,
          "BE BE": "13,748",
          "BE BE %": "94%",
          "BE BU": 538,
          "BE BU %": "4%",
          "N BE": 297,
          "N BE %": "2%",
          "TOTAAL": "14,583"
        },
        {
          "Woonplaats": "Saint-Georges-sur-Meuse",
          "NIS code": 64065,
          "BE BE": "5,552",
          "BE BE %": "82%",
          "BE BU": 808,
          "BE BU %": "12%",
          "N BE": 425,
          "N BE %": "6%",
          "TOTAAL": "6,785"
        },
        {
          "Woonplaats": "Saint-Ghislain",
          "NIS code": 53070,
          "BE BE": "15,480",
          "BE BE %": "70%",
          "BE BU": "4,232",
          "BE BU %": "19%",
          "N BE": "2,384",
          "N BE %": "11%",
          "TOTAAL": "22,096"
        },
        {
          "Woonplaats": "Saint-Hubert",
          "NIS code": 84059,
          "BE BE": "5,379",
          "BE BE %": "94%",
          "BE BU": 256,
          "BE BU %": "4%",
          "N BE": 92,
          "N BE %": "2%",
          "TOTAAL": "5,727"
        },
        {
          "Woonplaats": "Saint-Léger (Virton)",
          "NIS code": 85034,
          "BE BE": "2,771",
          "BE BE %": "87%",
          "BE BU": 285,
          "BE BU %": "9%",
          "N BE": 136,
          "N BE %": "4%",
          "TOTAAL": "3,192"
        },
        {
          "Woonplaats": "Saint-Nicolas (Luik)",
          "NIS code": 62093,
          "BE BE": "10,110",
          "BE BE %": "44%",
          "BE BU": "6,154",
          "BE BU %": "27%",
          "N BE": "6,837",
          "N BE %": "30%",
          "TOTAAL": "23,101"
        },
        {
          "Woonplaats": "Sainte-Ode",
          "NIS code": 82038,
          "BE BE": "2,030",
          "BE BE %": "93%",
          "BE BU": 94,
          "BE BU %": "4%",
          "N BE": 57,
          "N BE %": "3%",
          "TOTAAL": "2,181"
        },
        {
          "Woonplaats": "Sambreville",
          "NIS code": 92137,
          "BE BE": "19,972",
          "BE BE %": "73%",
          "BE BU": "4,248",
          "BE BU %": "16%",
          "N BE": "2,977",
          "N BE %": "11%",
          "TOTAAL": "27,197"
        },
        {
          "Woonplaats": "Sankt Vith",
          "NIS code": 63067,
          "BE BE": "8,106",
          "BE BE %": "91%",
          "BE BU": 465,
          "BE BU %": "5%",
          "N BE": 370,
          "N BE %": "4%",
          "TOTAAL": "8,941"
        },
        {
          "Woonplaats": "Schaarbeek",
          "NIS code": 21015,
          "BE BE": "42,399",
          "BE BE %": "40%",
          "BE BU": "26,743",
          "BE BU %": "25%",
          "N BE": "36,550",
          "N BE %": "35%",
          "TOTAAL": "105,692"
        },
        {
          "Woonplaats": "Schelle",
          "NIS code": 11038,
          "BE BE": "7,354",
          "BE BE %": "95%",
          "BE BU": 271,
          "BE BU %": "3%",
          "N BE": 118,
          "N BE %": "2%",
          "TOTAAL": "7,743"
        },
        {
          "Woonplaats": "Scherpenheuvel-Zichem",
          "NIS code": 24134,
          "BE BE": "21,174",
          "BE BE %": "97%",
          "BE BU": 360,
          "BE BU %": "2%",
          "N BE": 186,
          "N BE %": "1%",
          "TOTAAL": "21,720"
        },
        {
          "Woonplaats": "Schilde",
          "NIS code": 11039,
          "BE BE": "17,300",
          "BE BE %": "88%",
          "BE BU": 865,
          "BE BU %": "4%",
          "N BE": "1,397",
          "N BE %": "7%",
          "TOTAAL": "19,562"
        },
        {
          "Woonplaats": "Schoten",
          "NIS code": 11040,
          "BE BE": "29,622",
          "BE BE %": "90%",
          "BE BU": "1,540",
          "BE BU %": "5%",
          "N BE": "1,571",
          "N BE %": "5%",
          "TOTAAL": "32,733"
        },
        {
          "Woonplaats": "Seneffe",
          "NIS code": 52063,
          "BE BE": "8,676",
          "BE BE %": "82%",
          "BE BU": "1,195",
          "BE BU %": "11%",
          "N BE": 657,
          "N BE %": "6%",
          "TOTAAL": "10,528"
        },
        {
          "Woonplaats": "Seraing",
          "NIS code": 62096,
          "BE BE": "38,274",
          "BE BE %": "63%",
          "BE BU": "11,838",
          "BE BU %": "20%",
          "N BE": "10,445",
          "N BE %": "17%",
          "TOTAAL": "60,557"
        },
        {
          "Woonplaats": "Sint-Agatha-Berchem",
          "NIS code": 21003,
          "BE BE": "13,354",
          "BE BE %": "71%",
          "BE BU": "3,006",
          "BE BU %": "16%",
          "N BE": "2,375",
          "N BE %": "13%",
          "TOTAAL": "18,735"
        },
        {
          "Woonplaats": "Sint-Amands",
          "NIS code": 12034,
          "BE BE": "7,067",
          "BE BE %": "93%",
          "BE BU": 260,
          "BE BU %": "3%",
          "N BE": 233,
          "N BE %": "3%",
          "TOTAAL": "7,560"
        },
        {
          "Woonplaats": "Sint-Genesius-Rode",
          "NIS code": 23101,
          "BE BE": "13,308",
          "BE BE %": "74%",
          "BE BU": "1,790",
          "BE BU %": "10%",
          "N BE": "2,900",
          "N BE %": "16%",
          "TOTAAL": "17,998"
        },
        {
          "Woonplaats": "Sint-Gillis",
          "NIS code": 21013,
          "BE BE": "14,885",
          "BE BE %": "35%",
          "BE BU": "8,724",
          "BE BU %": "21%",
          "N BE": "18,849",
          "N BE %": "44%",
          "TOTAAL": "42,458"
        },
        {
          "Woonplaats": "Sint-Gillis-Waas",
          "NIS code": 46020,
          "BE BE": "15,584",
          "BE BE %": "90%",
          "BE BU": "1,230",
          "BE BU %": "7%",
          "N BE": 488,
          "N BE %": "3%",
          "TOTAAL": "17,302"
        },
        {
          "Woonplaats": "Sint-Jans-Molenbeek",
          "NIS code": 21012,
          "BE BE": "28,681",
          "BE BE %": "40%",
          "BE BU": "19,020",
          "BE BU %": "27%",
          "N BE": "23,518",
          "N BE %": "33%",
          "TOTAAL": "71,219"
        },
        {
          "Woonplaats": "Sint-Joost-ten-Node",
          "NIS code": 21014,
          "BE BE": "4,994",
          "BE BE %": "23%",
          "BE BU": "7,035",
          "BE BU %": "32%",
          "N BE": "10,068",
          "N BE %": "46%",
          "TOTAAL": "22,097"
        },
        {
          "Woonplaats": "Sint-Katelijne-Waver",
          "NIS code": 12035,
          "BE BE": "18,337",
          "BE BE %": "96%",
          "BE BU": 464,
          "BE BU %": "2%",
          "N BE": 237,
          "N BE %": "1%",
          "TOTAAL": "19,038"
        },
        {
          "Woonplaats": "Sint-Lambrechts-Woluwe",
          "NIS code": 21018,
          "BE BE": "30,281",
          "BE BE %": "65%",
          "BE BU": "5,590",
          "BE BU %": "12%",
          "N BE": "10,657",
          "N BE %": "23%",
          "TOTAAL": "46,528"
        },
        {
          "Woonplaats": "Sint-Laureins",
          "NIS code": 43014,
          "BE BE": "6,118",
          "BE BE %": "94%",
          "BE BU": 229,
          "BE BU %": "4%",
          "N BE": 166,
          "N BE %": "3%",
          "TOTAAL": "6,513"
        },
        {
          "Woonplaats": "Sint-Lievens-Houtem",
          "NIS code": 41063,
          "BE BE": "9,026",
          "BE BE %": "98%",
          "BE BU": 89,
          "BE BU %": "1%",
          "N BE": 56,
          "N BE %": "1%",
          "TOTAAL": "9,171"
        },
        {
          "Woonplaats": "Sint-Martens-Latem",
          "NIS code": 44064,
          "BE BE": "7,790",
          "BE BE %": "94%",
          "BE BU": 304,
          "BE BU %": "4%",
          "N BE": 220,
          "N BE %": "3%",
          "TOTAAL": "8,314"
        },
        {
          "Woonplaats": "Sint-Niklaas (Sint-Niklaas)",
          "NIS code": 46021,
          "BE BE": "61,517",
          "BE BE %": "90%",
          "BE BU": "3,715",
          "BE BU %": "5%",
          "N BE": "3,058",
          "N BE %": "4%",
          "TOTAAL": "68,290"
        },
        {
          "Woonplaats": "Sint-Pieters-Leeuw",
          "NIS code": 23077,
          "BE BE": "25,501",
          "BE BE %": "85%",
          "BE BU": "2,331",
          "BE BU %": "8%",
          "N BE": "2,181",
          "N BE %": "7%",
          "TOTAAL": "30,013"
        },
        {
          "Woonplaats": "Sint-Pieters-Woluwe",
          "NIS code": 21019,
          "BE BE": "24,646",
          "BE BE %": "65%",
          "BE BU": "3,476",
          "BE BU %": "9%",
          "N BE": "9,800",
          "N BE %": "26%",
          "TOTAAL": "37,922"
        },
        {
          "Woonplaats": "Sint-Truiden",
          "NIS code": 71053,
          "BE BE": "35,340",
          "BE BE %": "95%",
          "BE BU": 961,
          "BE BU %": "3%",
          "N BE": 846,
          "N BE %": "2%",
          "TOTAAL": "37,147"
        },
        {
          "Woonplaats": "Sivry-Rance",
          "NIS code": 56088,
          "BE BE": "3,784",
          "BE BE %": "84%",
          "BE BU": 562,
          "BE BU %": "12%",
          "N BE": 160,
          "N BE %": "4%",
          "TOTAAL": "4,506"
        },
        {
          "Woonplaats": "Sombreffe",
          "NIS code": 92114,
          "BE BE": "6,266",
          "BE BE %": "86%",
          "BE BU": 719,
          "BE BU %": "10%",
          "N BE": 320,
          "N BE %": "4%",
          "TOTAAL": "7,305"
        },
        {
          "Woonplaats": "Somme-Leuze",
          "NIS code": 91120,
          "BE BE": "3,804",
          "BE BE %": "91%",
          "BE BU": 242,
          "BE BU %": "6%",
          "N BE": 124,
          "N BE %": "3%",
          "TOTAAL": "4,170"
        },
        {
          "Woonplaats": "Soumagne",
          "NIS code": 62099,
          "BE BE": "11,255",
          "BE BE %": "77%",
          "BE BU": "2,251",
          "BE BU %": "15%",
          "N BE": "1,101",
          "N BE %": "8%",
          "TOTAAL": "14,607"
        },
        {
          "Woonplaats": "Spa",
          "NIS code": 63072,
          "BE BE": "9,076",
          "BE BE %": "88%",
          "BE BU": 823,
          "BE BU %": "8%",
          "N BE": 434,
          "N BE %": "4%",
          "TOTAAL": "10,333"
        },
        {
          "Woonplaats": "Spiere-Helkijn",
          "NIS code": 34043,
          "BE BE": "1,644",
          "BE BE %": "88%",
          "BE BU": 131,
          "BE BU %": "7%",
          "N BE": 83,
          "N BE %": "4%",
          "TOTAAL": "1,858"
        },
        {
          "Woonplaats": "Sprimont",
          "NIS code": 62100,
          "BE BE": "10,877",
          "BE BE %": "88%",
          "BE BU": "1,111",
          "BE BU %": "9%",
          "N BE": 438,
          "N BE %": "4%",
          "TOTAAL": "12,426"
        },
        {
          "Woonplaats": "Stabroek",
          "NIS code": 11044,
          "BE BE": "15,713",
          "BE BE %": "92%",
          "BE BU": 919,
          "BE BU %": "5%",
          "N BE": 511,
          "N BE %": "3%",
          "TOTAAL": "17,143"
        },
        {
          "Woonplaats": "Staden",
          "NIS code": 36019,
          "BE BE": "10,838",
          "BE BE %": "99%",
          "BE BU": 105,
          "BE BU %": "1%",
          "N BE": 43,
          "N BE %": "0%",
          "TOTAAL": "10,986"
        },
        {
          "Woonplaats": "Stavelot",
          "NIS code": 63073,
          "BE BE": "5,964",
          "BE BE %": "92%",
          "BE BU": 340,
          "BE BU %": "5%",
          "N BE": 175,
          "N BE %": "3%",
          "TOTAAL": "6,479"
        },
        {
          "Woonplaats": "Steenokkerzeel",
          "NIS code": 23081,
          "BE BE": "9,611",
          "BE BE %": "92%",
          "BE BU": 459,
          "BE BU %": "4%",
          "N BE": 349,
          "N BE %": "3%",
          "TOTAAL": "10,419"
        },
        {
          "Woonplaats": "Estaimpuis",
          "NIS code": 57027,
          "BE BE": "6,182",
          "BE BE %": "65%",
          "BE BU": "1,542",
          "BE BU %": "16%",
          "N BE": "1,814",
          "N BE %": "19%",
          "TOTAAL": "9,538"
        },
        {
          "Woonplaats": "Stekene",
          "NIS code": 46024,
          "BE BE": "14,827",
          "BE BE %": "90%",
          "BE BU": "1,052",
          "BE BU %": "6%",
          "N BE": 653,
          "N BE %": "4%",
          "TOTAAL": "16,532"
        },
        {
          "Woonplaats": "Stoumont",
          "NIS code": 63075,
          "BE BE": "2,680",
          "BE BE %": "93%",
          "BE BU": 107,
          "BE BU %": "4%",
          "N BE": 86,
          "N BE %": "3%",
          "TOTAAL": "2,873"
        },
        {
          "Woonplaats": "Tellin",
          "NIS code": 84068,
          "BE BE": "2,094",
          "BE BE %": "94%",
          "BE BU": 108,
          "BE BU %": "5%",
          "N BE": 27,
          "N BE %": "1%",
          "TOTAAL": "2,229"
        },
        {
          "Woonplaats": "Temse",
          "NIS code": 46025,
          "BE BE": "23,068",
          "BE BE %": "90%",
          "BE BU": "1,405",
          "BE BU %": "6%",
          "N BE": "1,060",
          "N BE %": "4%",
          "TOTAAL": "25,533"
        },
        {
          "Woonplaats": "Tenneville",
          "NIS code": 83049,
          "BE BE": "2,321",
          "BE BE %": "95%",
          "BE BU": 79,
          "BE BU %": "3%",
          "N BE": 49,
          "N BE %": "2%",
          "TOTAAL": "2,449"
        },
        {
          "Woonplaats": "Terhulpen",
          "NIS code": 25050,
          "BE BE": "5,396",
          "BE BE %": "78%",
          "BE BU": 602,
          "BE BU %": "9%",
          "N BE": 945,
          "N BE %": "14%",
          "TOTAAL": "6,943"
        },
        {
          "Woonplaats": "Ternat",
          "NIS code": 23086,
          "BE BE": "13,907",
          "BE BE %": "96%",
          "BE BU": 362,
          "BE BU %": "3%",
          "N BE": 149,
          "N BE %": "1%",
          "TOTAAL": "14,418"
        },
        {
          "Woonplaats": "Tervuren",
          "NIS code": 24104,
          "BE BE": "14,387",
          "BE BE %": "71%",
          "BE BU": "1,006",
          "BE BU %": "5%",
          "N BE": "4,788",
          "N BE %": "24%",
          "TOTAAL": "20,181"
        },
        {
          "Woonplaats": "Tessenderlo",
          "NIS code": 71057,
          "BE BE": "14,747",
          "BE BE %": "93%",
          "BE BU": 693,
          "BE BU %": "4%",
          "N BE": 379,
          "N BE %": "2%",
          "TOTAAL": "15,819"
        },
        {
          "Woonplaats": "Theux",
          "NIS code": 63076,
          "BE BE": "10,118",
          "BE BE %": "91%",
          "BE BU": 775,
          "BE BU %": "7%",
          "N BE": 277,
          "N BE %": "2%",
          "TOTAAL": "11,170"
        },
        {
          "Woonplaats": "Thimister-Clermont",
          "NIS code": 63089,
          "BE BE": "4,532",
          "BE BE %": "89%",
          "BE BU": 412,
          "BE BU %": "8%",
          "N BE": 131,
          "N BE %": "3%",
          "TOTAAL": "5,075"
        },
        {
          "Woonplaats": "Thuin",
          "NIS code": 56078,
          "BE BE": "12,446",
          "BE BE %": "85%",
          "BE BU": "1,640",
          "BE BU %": "11%",
          "N BE": 517,
          "N BE %": "4%",
          "TOTAAL": "14,603"
        },
        {
          "Woonplaats": "Tielt (Tielt)",
          "NIS code": 37015,
          "BE BE": "18,721",
          "BE BE %": "97%",
          "BE BU": 251,
          "BE BU %": "1%",
          "N BE": 236,
          "N BE %": "1%",
          "TOTAAL": "19,208"
        },
        {
          "Woonplaats": "Tielt-Winge",
          "NIS code": 24135,
          "BE BE": "9,561",
          "BE BE %": "97%",
          "BE BU": 174,
          "BE BU %": "2%",
          "N BE": 79,
          "N BE %": "1%",
          "TOTAAL": "9,814"
        },
        {
          "Woonplaats": "Tienen",
          "NIS code": 24107,
          "BE BE": "30,119",
          "BE BE %": "96%",
          "BE BU": 780,
          "BE BU %": "2%",
          "N BE": 580,
          "N BE %": "2%",
          "TOTAAL": "31,479"
        },
        {
          "Woonplaats": "Tinlot",
          "NIS code": 61081,
          "BE BE": "1,905",
          "BE BE %": "87%",
          "BE BU": 211,
          "BE BU %": "10%",
          "N BE": 68,
          "N BE %": "3%",
          "TOTAAL": "2,184"
        },
        {
          "Woonplaats": "Tintigny",
          "NIS code": 85039,
          "BE BE": "3,072",
          "BE BE %": "89%",
          "BE BU": 263,
          "BE BU %": "8%",
          "N BE": 101,
          "N BE %": "3%",
          "TOTAAL": "3,436"
        },
        {
          "Woonplaats": "Tongeren",
          "NIS code": 73083,
          "BE BE": "27,533",
          "BE BE %": "93%",
          "BE BU": "1,362",
          "BE BU %": "5%",
          "N BE": 828,
          "N BE %": "3%",
          "TOTAAL": "29,723"
        },
        {
          "Woonplaats": "Torhout",
          "NIS code": 31033,
          "BE BE": "18,412",
          "BE BE %": "98%",
          "BE BU": 307,
          "BE BU %": "2%",
          "N BE": 119,
          "N BE %": "1%",
          "TOTAAL": "18,838"
        },
        {
          "Woonplaats": "Tremelo",
          "NIS code": 24109,
          "BE BE": "12,590",
          "BE BE %": "94%",
          "BE BU": 361,
          "BE BU %": "3%",
          "N BE": 388,
          "N BE %": "3%",
          "TOTAAL": "13,339"
        },
        {
          "Woonplaats": "Trois-Ponts",
          "NIS code": 63086,
          "BE BE": "2,184",
          "BE BE %": "93%",
          "BE BU": 99,
          "BE BU %": "4%",
          "N BE": 63,
          "N BE %": "3%",
          "TOTAAL": "2,346"
        },
        {
          "Woonplaats": "Trooz",
          "NIS code": 62122,
          "BE BE": "5,923",
          "BE BE %": "77%",
          "BE BU": "1,142",
          "BE BU %": "15%",
          "N BE": 624,
          "N BE %": "8%",
          "TOTAAL": "7,689"
        },
        {
          "Woonplaats": "Tubeke",
          "NIS code": 25105,
          "BE BE": "14,710",
          "BE BE %": "69%",
          "BE BU": "3,222",
          "BE BU %": "15%",
          "N BE": "3,529",
          "N BE %": "16%",
          "TOTAAL": "21,461"
        },
        {
          "Woonplaats": "Turnhout",
          "NIS code": 13040,
          "BE BE": "34,238",
          "BE BE %": "89%",
          "BE BU": "2,145",
          "BE BU %": "6%",
          "N BE": "2,213",
          "N BE %": "6%",
          "TOTAAL": "38,596"
        },
        {
          "Woonplaats": "Ukkel",
          "NIS code": 21016,
          "BE BE": "46,188",
          "BE BE %": "62%",
          "BE BU": "10,430",
          "BE BU %": "14%",
          "N BE": "17,603",
          "N BE %": "24%",
          "TOTAAL": "74,221"
        },
        {
          "Woonplaats": "Vaux-sur-Sûre",
          "NIS code": 82036,
          "BE BE": "3,969",
          "BE BE %": "95%",
          "BE BU": 134,
          "BE BU %": "3%",
          "N BE": 73,
          "N BE %": "2%",
          "TOTAAL": "4,176"
        },
        {
          "Woonplaats": "Verlaine",
          "NIS code": 61063,
          "BE BE": "3,001",
          "BE BE %": "88%",
          "BE BU": 332,
          "BE BU %": "10%",
          "N BE": 96,
          "N BE %": "3%",
          "TOTAAL": "3,429"
        },
        {
          "Woonplaats": "Verviers",
          "NIS code": 63079,
          "BE BE": "40,271",
          "BE BE %": "76%",
          "BE BU": "6,970",
          "BE BU %": "13%",
          "N BE": "5,907",
          "N BE %": "11%",
          "TOTAAL": "53,148"
        },
        {
          "Woonplaats": "Veurne",
          "NIS code": 38025,
          "BE BE": "11,398",
          "BE BE %": "96%",
          "BE BU": 293,
          "BE BU %": "2%",
          "N BE": 163,
          "N BE %": "1%",
          "TOTAAL": "11,854"
        },
        {
          "Woonplaats": "Vielsalm",
          "NIS code": 82032,
          "BE BE": "6,582",
          "BE BE %": "92%",
          "BE BU": 388,
          "BE BU %": "5%",
          "N BE": 212,
          "N BE %": "3%",
          "TOTAAL": "7,182"
        },
        {
          "Woonplaats": "Villers-la-Ville",
          "NIS code": 25107,
          "BE BE": "7,852",
          "BE BE %": "86%",
          "BE BU": 900,
          "BE BU %": "10%",
          "N BE": 340,
          "N BE %": "4%",
          "TOTAAL": "9,092"
        },
        {
          "Woonplaats": "Villers-Le-Bouillet",
          "NIS code": 61068,
          "BE BE": "4,934",
          "BE BE %": "87%",
          "BE BU": 538,
          "BE BU %": "10%",
          "N BE": 181,
          "N BE %": "3%",
          "TOTAAL": "5,653"
        },
        {
          "Woonplaats": "Vilvoorde",
          "NIS code": 23088,
          "BE BE": "26,812",
          "BE BE %": "77%",
          "BE BU": "4,187",
          "BE BU %": "12%",
          "N BE": "3,983",
          "N BE %": "11%",
          "TOTAAL": "34,982"
        },
        {
          "Woonplaats": "Viroinval",
          "NIS code": 93090,
          "BE BE": "4,660",
          "BE BE %": "83%",
          "BE BU": 709,
          "BE BU %": "13%",
          "N BE": 254,
          "N BE %": "5%",
          "TOTAAL": "5,623"
        },
        {
          "Woonplaats": "Virton",
          "NIS code": 85045,
          "BE BE": "8,843",
          "BE BE %": "80%",
          "BE BU": "1,463",
          "BE BU %": "13%",
          "N BE": 703,
          "N BE %": "6%",
          "TOTAAL": "11,009"
        },
        {
          "Woonplaats": "Vleteren",
          "NIS code": 33041,
          "BE BE": "3,527",
          "BE BE %": "98%",
          "BE BU": 45,
          "BE BU %": "1%",
          "N BE": 21,
          "N BE %": "1%",
          "TOTAAL": "3,593"
        },
        {
          "Woonplaats": "Vloesberg",
          "NIS code": 51019,
          "BE BE": "2,929",
          "BE BE %": "92%",
          "BE BU": 186,
          "BE BU %": "6%",
          "N BE": 79,
          "N BE %": "2%",
          "TOTAAL": "3,194"
        },
        {
          "Woonplaats": "Voeren",
          "NIS code": 73109,
          "BE BE": "2,923",
          "BE BE %": "68%",
          "BE BU": 585,
          "BE BU %": "14%",
          "N BE": 807,
          "N BE %": "19%",
          "TOTAAL": "4,315"
        },
        {
          "Woonplaats": "Vorselaar",
          "NIS code": 13044,
          "BE BE": "7,116",
          "BE BE %": "97%",
          "BE BU": 158,
          "BE BU %": "2%",
          "N BE": 79,
          "N BE %": "1%",
          "TOTAAL": "7,353"
        },
        {
          "Woonplaats": "Vorst (Brussel-Hoofdstad)",
          "NIS code": 21007,
          "BE BE": "22,524",
          "BE BE %": "49%",
          "BE BU": "9,953",
          "BE BU %": "22%",
          "N BE": "13,078",
          "N BE %": "29%",
          "TOTAAL": "45,555"
        },
        {
          "Woonplaats": "Vosselaar",
          "NIS code": 13046,
          "BE BE": "9,357",
          "BE BE %": "93%",
          "BE BU": 341,
          "BE BU %": "3%",
          "N BE": 317,
          "N BE %": "3%",
          "TOTAAL": "10,015"
        },
        {
          "Woonplaats": "Vresse-sur-Semois",
          "NIS code": 91143,
          "BE BE": "2,405",
          "BE BE %": "86%",
          "BE BU": 240,
          "BE BU %": "9%",
          "N BE": 165,
          "N BE %": "6%",
          "TOTAAL": "2,810"
        },
        {
          "Woonplaats": "Waarschoot",
          "NIS code": 44072,
          "BE BE": "7,843",
          "BE BE %": "98%",
          "BE BU": 103,
          "BE BU %": "1%",
          "N BE": 54,
          "N BE %": "1%",
          "TOTAAL": "8,000"
        },
        {
          "Woonplaats": "Waasmunster",
          "NIS code": 42023,
          "BE BE": "9,798",
          "BE BE %": "96%",
          "BE BU": 240,
          "BE BU %": "2%",
          "N BE": 199,
          "N BE %": "2%",
          "TOTAAL": "10,237"
        },
        {
          "Woonplaats": "Wachtebeke",
          "NIS code": 44073,
          "BE BE": "6,339",
          "BE BE %": "93%",
          "BE BU": 298,
          "BE BU %": "4%",
          "N BE": 172,
          "N BE %": "3%",
          "TOTAAL": "6,809"
        },
        {
          "Woonplaats": "Walcourt",
          "NIS code": 93088,
          "BE BE": "14,585",
          "BE BE %": "87%",
          "BE BU": "1,560",
          "BE BU %": "9%",
          "N BE": 695,
          "N BE %": "4%",
          "TOTAAL": "16,840"
        },
        {
          "Woonplaats": "Walhain",
          "NIS code": 25124,
          "BE BE": "4,903",
          "BE BE %": "91%",
          "BE BU": 376,
          "BE BU %": "7%",
          "N BE": 133,
          "N BE %": "2%",
          "TOTAAL": "5,412"
        },
        {
          "Woonplaats": "Wanze",
          "NIS code": 61072,
          "BE BE": "10,677",
          "BE BE %": "89%",
          "BE BU": 965,
          "BE BU %": "8%",
          "N BE": 384,
          "N BE %": "3%",
          "TOTAAL": "12,026"
        },
        {
          "Woonplaats": "Waregem",
          "NIS code": 34040,
          "BE BE": "34,254",
          "BE BE %": "96%",
          "BE BU": 983,
          "BE BU %": "3%",
          "N BE": 602,
          "N BE %": "2%",
          "TOTAAL": "35,839"
        },
        {
          "Woonplaats": "Wasseiges",
          "NIS code": 64075,
          "BE BE": "2,075",
          "BE BE %": "93%",
          "BE BU": 129,
          "BE BU %": "6%",
          "N BE": 37,
          "N BE %": "2%",
          "TOTAAL": "2,241"
        },
        {
          "Woonplaats": "Waterloo",
          "NIS code": 25110,
          "BE BE": "19,861",
          "BE BE %": "69%",
          "BE BU": "3,412",
          "BE BU %": "12%",
          "N BE": "5,713",
          "N BE %": "20%",
          "TOTAAL": "28,986"
        },
        {
          "Woonplaats": "Watermaal-Bosvoorde",
          "NIS code": 21017,
          "BE BE": "18,244",
          "BE BE %": "74%",
          "BE BU": "2,850",
          "BE BU %": "12%",
          "N BE": "3,679",
          "N BE %": "15%",
          "TOTAAL": "24,773"
        },
        {
          "Woonplaats": "Waver",
          "NIS code": 25112,
          "BE BE": "25,050",
          "BE BE %": "81%",
          "BE BU": "3,635",
          "BE BU %": "12%",
          "N BE": "2,348",
          "N BE %": "8%",
          "TOTAAL": "31,033"
        },
        {
          "Woonplaats": "Weismes",
          "NIS code": 63080,
          "BE BE": "5,938",
          "BE BE %": "91%",
          "BE BU": 336,
          "BE BU %": "5%",
          "N BE": 229,
          "N BE %": "4%",
          "TOTAAL": "6,503"
        },
        {
          "Woonplaats": "Welkenraedt",
          "NIS code": 63084,
          "BE BE": "6,930",
          "BE BE %": "79%",
          "BE BU": "1,292",
          "BE BU %": "15%",
          "N BE": 579,
          "N BE %": "7%",
          "TOTAAL": "8,801"
        },
        {
          "Woonplaats": "Wellen",
          "NIS code": 73098,
          "BE BE": "6,494",
          "BE BE %": "97%",
          "BE BU": 139,
          "BE BU %": "2%",
          "N BE": 92,
          "N BE %": "1%",
          "TOTAAL": "6,725"
        },
        {
          "Woonplaats": "Wellin",
          "NIS code": 84075,
          "BE BE": "2,631",
          "BE BE %": "93%",
          "BE BU": 153,
          "BE BU %": "5%",
          "N BE": 53,
          "N BE %": "2%",
          "TOTAAL": "2,837"
        },
        {
          "Woonplaats": "Wemmel",
          "NIS code": 23102,
          "BE BE": "11,526",
          "BE BE %": "83%",
          "BE BU": "1,393",
          "BE BU %": "10%",
          "N BE": "1,013",
          "N BE %": "7%",
          "TOTAAL": "13,932"
        },
        {
          "Woonplaats": "Wervik",
          "NIS code": 33029,
          "BE BE": "15,886",
          "BE BE %": "89%",
          "BE BU": "1,396",
          "BE BU %": "8%",
          "N BE": 514,
          "N BE %": "3%",
          "TOTAAL": "17,796"
        },
        {
          "Woonplaats": "Westerlo",
          "NIS code": 13049,
          "BE BE": "21,185",
          "BE BE %": "96%",
          "BE BU": 492,
          "BE BU %": "2%",
          "N BE": 283,
          "N BE %": "1%",
          "TOTAAL": "21,960"
        },
        {
          "Woonplaats": "Wetteren",
          "NIS code": 42025,
          "BE BE": "21,782",
          "BE BE %": "96%",
          "BE BU": 651,
          "BE BU %": "3%",
          "N BE": 362,
          "N BE %": "2%",
          "TOTAAL": "22,795"
        },
        {
          "Woonplaats": "Wevelgem",
          "NIS code": 34041,
          "BE BE": "30,279",
          "BE BE %": "97%",
          "BE BU": 733,
          "BE BU %": "2%",
          "N BE": 362,
          "N BE %": "1%",
          "TOTAAL": "31,374"
        },
        {
          "Woonplaats": "Wezembeek-Oppem",
          "NIS code": 23103,
          "BE BE": "9,814",
          "BE BE %": "72%",
          "BE BU": "1,104",
          "BE BU %": "8%",
          "N BE": "2,704",
          "N BE %": "20%",
          "TOTAAL": "13,622"
        },
        {
          "Woonplaats": "Wezet",
          "NIS code": 62108,
          "BE BE": "12,199",
          "BE BE %": "73%",
          "BE BU": "2,516",
          "BE BU %": "15%",
          "N BE": "1,902",
          "N BE %": "11%",
          "TOTAAL": "16,617"
        },
        {
          "Woonplaats": "Wichelen",
          "NIS code": 42026,
          "BE BE": "10,737",
          "BE BE %": "98%",
          "BE BU": 159,
          "BE BU %": "1%",
          "N BE": 65,
          "N BE %": "1%",
          "TOTAAL": "10,961"
        },
        {
          "Woonplaats": "Wielsbeke",
          "NIS code": 37017,
          "BE BE": "8,474",
          "BE BE %": "95%",
          "BE BU": 250,
          "BE BU %": "3%",
          "N BE": 187,
          "N BE %": "2%",
          "TOTAAL": "8,911"
        },
        {
          "Woonplaats": "Wijnegem",
          "NIS code": 11050,
          "BE BE": "8,047",
          "BE BE %": "93%",
          "BE BU": 395,
          "BE BU %": "5%",
          "N BE": 218,
          "N BE %": "3%",
          "TOTAAL": "8,660"
        },
        {
          "Woonplaats": "Willebroek",
          "NIS code": 12040,
          "BE BE": "20,129",
          "BE BE %": "89%",
          "BE BU": "1,234",
          "BE BU %": "5%",
          "N BE": "1,145",
          "N BE %": "5%",
          "TOTAAL": "22,508"
        },
        {
          "Woonplaats": "Wingene",
          "NIS code": 37018,
          "BE BE": "12,331",
          "BE BE %": "99%",
          "BE BU": 117,
          "BE BU %": "1%",
          "N BE": 69,
          "N BE %": "1%",
          "TOTAAL": "12,517"
        },
        {
          "Woonplaats": "Wommelgem",
          "NIS code": 11052,
          "BE BE": "11,072",
          "BE BE %": "94%",
          "BE BU": 426,
          "BE BU %": "4%",
          "N BE": 264,
          "N BE %": "2%",
          "TOTAAL": "11,762"
        },
        {
          "Woonplaats": "Wortegem-Petegem",
          "NIS code": 45061,
          "BE BE": "5,988",
          "BE BE %": "99%",
          "BE BU": 53,
          "BE BU %": "1%",
          "N BE": 30,
          "N BE %": "0%",
          "TOTAAL": "6,071"
        },
        {
          "Woonplaats": "Wuustwezel",
          "NIS code": 11053,
          "BE BE": "15,516",
          "BE BE %": "88%",
          "BE BU": "1,236",
          "BE BU %": "7%",
          "N BE": 800,
          "N BE %": "5%",
          "TOTAAL": "17,552"
        },
        {
          "Woonplaats": "Yvoir",
          "NIS code": 91141,
          "BE BE": "6,998",
          "BE BE %": "88%",
          "BE BU": 593,
          "BE BU %": "7%",
          "N BE": 323,
          "N BE %": "4%",
          "TOTAAL": "7,914"
        },
        {
          "Woonplaats": "Zandhoven",
          "NIS code": 11054,
          "BE BE": "11,611",
          "BE BE %": "96%",
          "BE BU": 307,
          "BE BU %": "3%",
          "N BE": 167,
          "N BE %": "1%",
          "TOTAAL": "12,085"
        },
        {
          "Woonplaats": "Zaventem",
          "NIS code": 23094,
          "BE BE": "20,910",
          "BE BE %": "78%",
          "BE BU": "2,114",
          "BE BU %": "8%",
          "N BE": "3,877",
          "N BE %": "14%",
          "TOTAAL": "26,901"
        },
        {
          "Woonplaats": "Zedelgem",
          "NIS code": 31040,
          "BE BE": "21,553",
          "BE BE %": "98%",
          "BE BU": 311,
          "BE BU %": "1%",
          "N BE": 156,
          "N BE %": "1%",
          "TOTAAL": "22,020"
        },
        {
          "Woonplaats": "Zele",
          "NIS code": 42028,
          "BE BE": "18,467",
          "BE BE %": "91%",
          "BE BU": 788,
          "BE BU %": "4%",
          "N BE": "1,139",
          "N BE %": "6%",
          "TOTAAL": "20,394"
        },
        {
          "Woonplaats": "Zelzate",
          "NIS code": 43018,
          "BE BE": "10,393",
          "BE BE %": "86%",
          "BE BU": "1,083",
          "BE BU %": "9%",
          "N BE": 618,
          "N BE %": "5%",
          "TOTAAL": "12,094"
        },
        {
          "Woonplaats": "Zemst",
          "NIS code": 23096,
          "BE BE": "19,379",
          "BE BE %": "94%",
          "BE BU": 781,
          "BE BU %": "4%",
          "N BE": 393,
          "N BE %": "2%",
          "TOTAAL": "20,553"
        },
        {
          "Woonplaats": "Zingem",
          "NIS code": 45057,
          "BE BE": "6,479",
          "BE BE %": "98%",
          "BE BU": 89,
          "BE BU %": "1%",
          "N BE": 35,
          "N BE %": "1%",
          "TOTAAL": "6,603"
        },
        {
          "Woonplaats": "Zinnik",
          "NIS code": 55040,
          "BE BE": "20,295",
          "BE BE %": "83%",
          "BE BU": "2,151",
          "BE BU %": "9%",
          "N BE": "2,136",
          "N BE %": "9%",
          "TOTAAL": "24,582"
        },
        {
          "Woonplaats": "Zoersel",
          "NIS code": 11055,
          "BE BE": "18,967",
          "BE BE %": "94%",
          "BE BU": 681,
          "BE BU %": "3%",
          "N BE": 516,
          "N BE %": "3%",
          "TOTAAL": "20,164"
        },
        {
          "Woonplaats": "Zomergem",
          "NIS code": 44080,
          "BE BE": "8,017",
          "BE BE %": "97%",
          "BE BU": 163,
          "BE BU %": "2%",
          "N BE": 65,
          "N BE %": "1%",
          "TOTAAL": "8,245"
        },
        {
          "Woonplaats": "Zonhoven",
          "NIS code": 71066,
          "BE BE": "17,067",
          "BE BE %": "90%",
          "BE BU": "1,196",
          "BE BU %": "6%",
          "N BE": 677,
          "N BE %": "4%",
          "TOTAAL": "18,940"
        },
        {
          "Woonplaats": "Zonnebeke",
          "NIS code": 33037,
          "BE BE": "11,213",
          "BE BE %": "97%",
          "BE BU": 249,
          "BE BU %": "2%",
          "N BE": 83,
          "N BE %": "1%",
          "TOTAAL": "11,545"
        },
        {
          "Woonplaats": "Zottegem",
          "NIS code": 41081,
          "BE BE": "24,057",
          "BE BE %": "98%",
          "BE BU": 372,
          "BE BU %": "2%",
          "N BE": 145,
          "N BE %": "1%",
          "TOTAAL": "24,574"
        },
        {
          "Woonplaats": "Zoutleeuw",
          "NIS code": 24130,
          "BE BE": "7,592",
          "BE BE %": "97%",
          "BE BU": 136,
          "BE BU %": "2%",
          "N BE": 63,
          "N BE %": "1%",
          "TOTAAL": "7,791"
        },
        {
          "Woonplaats": "Zuienkerke",
          "NIS code": 31042,
          "BE BE": "2,713",
          "BE BE %": "97%",
          "BE BU": 55,
          "BE BU %": "2%",
          "N BE": 22,
          "N BE %": "1%",
          "TOTAAL": "2,790"
        },
        {
          "Woonplaats": "Zulte",
          "NIS code": 44081,
          "BE BE": "14,186",
          "BE BE %": "98%",
          "BE BU": 181,
          "BE BU %": "1%",
          "N BE": 161,
          "N BE %": "1%",
          "TOTAAL": "14,528"
        },
        {
          "Woonplaats": "Zutendaal",
          "NIS code": 71067,
          "BE BE": "5,579",
          "BE BE %": "83%",
          "BE BU": 620,
          "BE BU %": "9%",
          "N BE": 556,
          "N BE %": "8%",
          "TOTAAL": "6,755"
        },
        {
          "Woonplaats": "Zwalm",
          "NIS code": 45065,
          "BE BE": "7,545",
          "BE BE %": "98%",
          "BE BU": 98,
          "BE BU %": "1%",
          "N BE": 40,
          "N BE %": "1%",
          "TOTAAL": "7,683"
        },
        {
          "Woonplaats": "Zwevegem",
          "NIS code": 34042,
          "BE BE": "22,863",
          "BE BE %": "97%",
          "BE BU": 436,
          "BE BU %": "2%",
          "N BE": 190,
          "N BE %": "1%",
          "TOTAAL": "23,489"
        },
        {
          "Woonplaats": "Zwijndrecht",
          "NIS code": 11056,
          "BE BE": "16,426",
          "BE BE %": "92%",
          "BE BU": 958,
          "BE BU %": "5%",
          "N BE": 417,
          "N BE %": "2%",
          "TOTAAL": "17,801"
        },
        {
          "Woonplaats": "’s Gravenbrakel",
          "NIS code": 55004,
          "BE BE": "15,968",
          "BE BE %": "84%",
          "BE BU": "1,888",
          "BE BU %": "10%",
          "N BE": "1,228",
          "N BE %": "6%",
          "TOTAAL": "19,084"
        }
      ]
    }
  },
  methods: {
  },
  mounted () {
    function resizeFrame () {
      let message = { height: document.body.scrollHeight, width: document.body.scrollWidth }
      window.top.postMessage(message)
    }

    window.addEventListener('load', function () {
      resizeFrame()
    })
    resizeFrame()
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
